import HiChevronDownIcon from "@icon/HiChevronDownIcon.tsx";
import TbRefreshIcon from "@icon/TbRefreshIcon.tsx";
import Button from "@ui/Button.tsx";
import ConfirmationModal from "@ui/ConfirmationModal.tsx";
import Input from "@ui/Input.tsx";
import Loading from "@ui/Loading.tsx";
import { useModal } from "@ui/modal";
import { FormEvent, useCallback, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import CustomDropdown from "../../components/CustomDropdown.tsx";
import DownloadTransferDropdown from "../../components/DownloadTransferDropdown.tsx";
import { AppLayout } from "../../components/layouts";
import TransferDetail from "../../components/TransferDetail.tsx";
import { ENDPOINTS, PERMISSIONS, RESPONSE_STATUS } from "../../constants";
import { updateAxiosBaseUrl, userHavePermissions } from "../../helpers";
import useManageTransfer from "../../hooks/useManageTransfer.ts";
import { cn } from "../../libs/style.ts";
import useToast from "../../libs/useToast.tsx";
import { ApiService, TRANSACTION_STATUS } from "../../types";

type ValidateType = "validatePayIn" | "validatePayOut";

type TransferActionType =
    | "refund"
    | "setPayInFail"
    | "setPayOutFail"
    | "retry"
    | "resend"
    | "validation"
    | null;

interface ValidationDataType {
    validationType: ValidateType;
    ref: string;
}

interface UpdateTransferStatusDataType {
    transfer_id: string;
    status_transfer?: "pending" | "failed" | "success";
    status_pay_in?: "pending" | "failed" | "success";
    status_pay_out?: "pending" | "failed" | "success";
    ref_pay_in_partner?: string;
    ref_pay_out_partner?: string;
}

const TransferDetails = () => {
    const [transferActionType, setTransferActionType] =
        useState<TransferActionType>(null);
    const [loading, setLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState<{
        [key: string]: string;
    }>({});
    const [validationData, setValidationData] =
        useState<ValidationDataType | null>(null);
    const payInRef = useRef<(EventTarget & HTMLFormElement) | null>(null);
    const payOutRef = useRef<(EventTarget & HTMLFormElement) | null>(null);

    const navigate = useNavigate();
    const { id: transferId } = useParams();
    const { isOpen, closeModal, openModal } = useModal();
    const {
        transfer,
        setTransfer,
        fetchingTransfer,
        refundLoading,
        handleRefund,
        canRefundTransfer
    } = useManageTransfer(transferId?.toString() ?? "");
    const { customSuccess: successToast, customError: errorToast } = useToast();

    const handleSubmitValidation = useCallback(
        (validationType: ValidateType) => {
            return (e: FormEvent<HTMLFormElement>) => {
                let error = false;
                e.preventDefault();
                if (!payInRef.current) {
                    payInRef.current = e.currentTarget;
                }

                if (!payOutRef.current) {
                    payOutRef.current = e.currentTarget;
                }

                const formData = new FormData(e.currentTarget);
                let ref = "";

                if (validationType === "validatePayIn") {
                    ref = formData.get("payIn")?.toString()?.trim() || "";
                    if (ref === "") {
                        error = true;
                        setValidationErrors(error => ({
                            ...error,
                            payIn: "Référence PayIn est un champ obligatoire"
                        }));
                    }
                }

                if (validationType === "validatePayOut") {
                    ref = formData.get("payOut")?.toString()?.trim() || "";
                    if (ref === "") {
                        error = true;
                        setValidationErrors(error => ({
                            ...error,
                            payOut: "Référence PayOut est un champ obligatoire"
                        }));
                    }
                }

                setTransferActionType("validation");
                setValidationData({
                    validationType,
                    ref: ref
                });
                !error && openModal();
            };
        },
        [openModal]
    );

    const updateTransferStatus = useCallback(
        (
            data: UpdateTransferStatusDataType,
            message?: { successMessage?: string; errorMessage: string }
        ) => {
            if (transfer) {
                updateAxiosBaseUrl(ApiService.moc);
                setLoading(true);
                window.axios
                    .put(
                        ENDPOINTS.UPDATE_TRANSFER_STATUS.replace(
                            ":id",
                            transfer.id.toString()
                        ),
                        data
                    )
                    .then(response => {
                        const data = response.data.data;
                        if (
                            data &&
                            !Array.isArray(data) &&
                            typeof data === "object"
                        ) {
                            setTransfer(response.data.data);
                        }
                        successToast({
                            message:
                                message?.successMessage ||
                                "Opération effectuée avec succès"
                        });
                        closeModal();
                        setValidationData(null);
                        payInRef.current?.reset();
                        payOutRef.current?.reset();
                        setValidationErrors({});
                    })
                    .catch(error => {
                        const status = error.response?.status;
                        const message = error.response?.data?.message || "";

                        if (status === RESPONSE_STATUS.CONFLICT) {
                            errorToast(
                                {
                                    message
                                },
                                { duration: 10000 }
                            );
                        } else {
                            errorToast({
                                message:
                                    message?.errorMessage ||
                                    "Echec de l'opération"
                            });
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        },
        [closeModal, errorToast, setTransfer, successToast, transfer]
    );

    const handleResend = useCallback(() => {
        if (transfer) {
            setLoading(true);
            updateAxiosBaseUrl(ApiService.moc);
            window.axios
                .post(
                    ENDPOINTS.RESEND_TRANSFER.replace(
                        ":id",
                        transfer.id.toString()
                    )
                )
                .then(response => {
                    const data = response.data.data;
                    if (
                        data &&
                        !Array.isArray(data) &&
                        typeof data === "object"
                    ) {
                        setTransfer(response.data.data);
                    }
                    successToast({
                        message: "Opération effectuée avec succès"
                    });
                    closeModal();
                })
                .catch(error => {
                    const status = error.response?.status;
                    const message = error.response?.data?.message || "";

                    if (status === RESPONSE_STATUS.CONFLICT) {
                        errorToast({ message }, { duration: 10000 });
                    } else {
                        errorToast({ message: "Echec de l'opération" });
                    }
                })
                .finally(() => setLoading(false));
        }
    }, [closeModal, errorToast, setTransfer, successToast, transfer]);

    const handleRetry = useCallback(() => {
        if (transfer) {
            setLoading(true);
            updateAxiosBaseUrl(ApiService.moc);
            window.axios
                .post(
                    ENDPOINTS.RETRY_TRANSFER.replace(
                        ":id",
                        transfer.id.toString()
                    )
                )
                .then(response => {
                    const data = response.data.data;
                    if (
                        data &&
                        !Array.isArray(data) &&
                        typeof data === "object"
                    ) {
                        setTransfer(response.data.data);
                    }
                    successToast(
                        {
                            message: "Opération effectuée avec succès"
                        },
                        { duration: 10000 }
                    );
                    closeModal();
                })
                .catch(error => {
                    const status = error.response?.status;
                    const message = error.response?.data?.message || "";

                    if (status === RESPONSE_STATUS.CONFLICT) {
                        errorToast({ message }, { duration: 10000 });
                    } else {
                        errorToast({ message: "Echec de l'opération" });
                    }
                })
                .finally(() => setLoading(false));
        }
    }, [closeModal, errorToast, setTransfer, successToast, transfer]);

    const handleConfirmModal = useCallback(() => {
        if (transfer) {
            const data: UpdateTransferStatusDataType = {
                transfer_id: transfer.id.toString()
            };

            if (transferActionType === "refund") {
                handleRefund(undefined, () => {
                    closeModal();
                });
            }

            if (
                transferActionType === "setPayInFail" ||
                transferActionType === "setPayOutFail"
            ) {
                if (transferActionType === "setPayInFail")
                    data.status_pay_in = "failed";

                if (transferActionType === "setPayOutFail")
                    data.status_pay_out = "failed";

                updateTransferStatus(data);
            }

            if (transferActionType === "validation") {
                if (validationData?.validationType === "validatePayIn") {
                    data.ref_pay_in_partner = validationData.ref;
                    data.status_pay_in = "success";
                }

                if (validationData?.validationType === "validatePayOut") {
                    data.ref_pay_out_partner = validationData.ref;
                    data.status_pay_out = "success";
                }

                updateTransferStatus(data);
            }

            if (transferActionType === "retry") {
                handleRetry();
            }

            if (transferActionType === "resend") {
                handleResend();
            }
        }
    }, [
        closeModal,
        handleRefund,
        handleResend,
        handleRetry,
        transfer,
        transferActionType,
        updateTransferStatus,
        validationData?.ref,
        validationData?.validationType
    ]);

    const refundTransfer = useMemo(() => {
        if (!transfer) return false;

        return canRefundTransfer(transfer);
    }, [canRefundTransfer, transfer]);

    const confirmModalDescription = useMemo(() => {
        const label =
            validationData?.validationType === "validatePayIn"
                ? "Pay In"
                : "Pay Out";

        switch (transferActionType) {
            case "refund":
                return "Voulez vous rembourser cet transfert";
            case "setPayInFail":
                return "Voulez vous mettre en échec le Pay In de cet transfert";
            case "setPayOutFail":
                return "Voulez vous mettre en échec le Pay Out de cet transfert";
            case "retry":
                return "Voulez vous réessayer cet transfert";
            case "resend":
                return "Voulez vous renvoyer cet transfert";
            case "validation":
                return `Voulez vous valider le ${label} de ce transfert`;
            default:
                return "";
        }
    }, [transferActionType, validationData?.validationType]);

    const showActions = useMemo(() => {
        const payIn = transfer?.status_pay_in === "pending";

        const payOut =
            transfer?.status_pay_in === "success" &&
            (transfer?.status_pay_out === "failed" ||
                transfer?.status_pay_out === "pending" ||
                !transfer?.status_pay_out);

        const resend =
            userHavePermissions([PERMISSIONS.RESEND_TRANSFER]) &&
            transfer?.status_pay_in === "success" &&
            transfer?.status_pay_out === "failed";

        const retry =
            userHavePermissions([PERMISSIONS.RETRY_TRANSFER]) &&
            transfer?.status_pay_out === "failed" &&
            transfer?.status_pay_in === "success";

        const setToFailedPayIn = transfer?.status_pay_in === "pending";
        const setToFailedPayout =
            transfer?.status_pay_in === "success" &&
            (transfer?.status_pay_out === "pending" ||
                !transfer?.status_pay_out);

        return {
            validatePayIn: payIn,
            validatePayOut: payOut,
            resend,
            retry,
            resendOrRetry: resend || retry,
            setToFailed:
                userHavePermissions([PERMISSIONS.UPDATE_TRANSFER_STATUS]) &&
                (setToFailedPayIn || setToFailedPayout),
            setToFailedPayIn,
            setToFailedPayout,
            refundTransfer:
                refundTransfer &&
                userHavePermissions([PERMISSIONS.REFUND_TRANSFER])
        };
    }, [refundTransfer, transfer?.status_pay_in, transfer?.status_pay_out]);

    return (
        <AppLayout>
            <div className="bg-white border rounded-md px-4 py-5">
                <button
                    onClick={() => navigate(-1)}
                    className="mr-3 flex items-center space-x-4"
                >
                    <HiChevronDownIcon size="xl" className="rotate-90" />
                    <span>Revenir en arrière</span>
                </button>

                <h3 className="text-xl font-semibold my-6">
                    Détails du transfert
                </h3>

                {fetchingTransfer ? (
                    <div className="h-56 flex items-center justify-center">
                        <Loading className="h-10 w-10 text-blue-600" />
                    </div>
                ) : (
                    <>
                        {!fetchingTransfer && !transfer && (
                            <div className="h-56 flex items-center justify-center text-lg text-gray-500 font-semibold">
                                Aucun transfert trouvé
                            </div>
                        )}

                        {transfer && (
                            <>
                                <TransferDetail transfer={transfer} />

                                {transfer?.status !==
                                TRANSACTION_STATUS.REFUND ? (
                                    <>
                                        {(showActions.validatePayIn ||
                                            showActions.validatePayOut) &&
                                            userHavePermissions([
                                                PERMISSIONS.UPDATE_TRANSFER_STATUS
                                            ]) && (
                                                <div
                                                    className={cn({
                                                        "grid grid-cols-2 gap-10 border-t px-6 py-4":
                                                            true,
                                                        "mt-4": userHavePermissions(
                                                            [
                                                                PERMISSIONS.UPDATE_TRANSFER_STATUS
                                                            ]
                                                        )
                                                    })}
                                                >
                                                    {showActions.validatePayIn && (
                                                        <form
                                                            onSubmit={handleSubmitValidation(
                                                                "validatePayIn"
                                                            )}
                                                            className="flex items-center space-x-2"
                                                        >
                                                            <Input
                                                                type="text"
                                                                placeholder="Référence Payin"
                                                                name="payIn"
                                                                error={
                                                                    validationErrors?.payIn
                                                                }
                                                            />
                                                            <Button
                                                                type="submit"
                                                                withAuto
                                                                variant="gray"
                                                                className="mt-2"
                                                            >
                                                                Valider
                                                            </Button>
                                                        </form>
                                                    )}

                                                    {showActions.validatePayOut && (
                                                        <form
                                                            onSubmit={handleSubmitValidation(
                                                                "validatePayOut"
                                                            )}
                                                            className="flex items-center space-x-2"
                                                        >
                                                            <Input
                                                                type="text"
                                                                placeholder="Référence Payout"
                                                                name="payOut"
                                                                error={
                                                                    validationErrors?.payOut
                                                                }
                                                            />
                                                            <Button
                                                                type="submit"
                                                                withAuto
                                                                variant="gray"
                                                                className="mt-2"
                                                            >
                                                                Valider
                                                            </Button>
                                                        </form>
                                                    )}
                                                </div>
                                            )}

                                        {(showActions.setToFailed ||
                                            showActions.resendOrRetry ||
                                            showActions.refundTransfer ||
                                            !!transfer) && (
                                            <div className="mt-4 p-6 border-t flex items-center justify-end space-x-4">
                                                {showActions.setToFailed && (
                                                    <CustomDropdown
                                                        buttonText="Mettre à failed"
                                                        items={[
                                                            {
                                                                label: "Pay In",
                                                                action: () => {
                                                                    openModal();
                                                                    setTransferActionType(
                                                                        "setPayInFail"
                                                                    );
                                                                },
                                                                show: showActions.setToFailedPayIn
                                                            },
                                                            {
                                                                label: "Pay Out",
                                                                action: () => {
                                                                    openModal();
                                                                    setTransferActionType(
                                                                        "setPayOutFail"
                                                                    );
                                                                },
                                                                show: showActions.setToFailedPayout
                                                            }
                                                        ]}
                                                    />
                                                )}

                                                {showActions.resendOrRetry && (
                                                    <CustomDropdown
                                                        buttonText="Action transfert"
                                                        items={[
                                                            {
                                                                label: "Renvoyer",
                                                                action: () => {
                                                                    openModal();
                                                                    setTransferActionType(
                                                                        "resend"
                                                                    );
                                                                },
                                                                show: showActions.resend
                                                            },
                                                            {
                                                                label: "Réessayer",
                                                                action: () => {
                                                                    openModal();
                                                                    setTransferActionType(
                                                                        "retry"
                                                                    );
                                                                },
                                                                show: showActions.retry
                                                            }
                                                        ]}
                                                    />
                                                )}

                                                {showActions.refundTransfer && (
                                                    <Button
                                                        onClick={() => {
                                                            setTransferActionType(
                                                                "refund"
                                                            );
                                                            openModal();
                                                        }}
                                                        variant="gray"
                                                        withAuto
                                                        icon={<TbRefreshIcon />}
                                                    >
                                                        Rembourser
                                                    </Button>
                                                )}

                                                <DownloadTransferDropdown
                                                    transfer={transfer}
                                                    typeButton="button"
                                                />
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="mt-4 p-6 border-t flex items-center justify-end space-x-4">
                                        <DownloadTransferDropdown
                                            transfer={transfer}
                                            typeButton="button"
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>

            <ConfirmationModal
                title="Êtes-vous sûr"
                description={confirmModalDescription}
                loading={refundLoading || loading}
                isOpen={isOpen}
                onConfirm={handleConfirmModal}
                closeModal={closeModal}
            />
        </AppLayout>
    );
};

export default TransferDetails;
