import { NavigateFunction } from "react-router-dom";

import config from "@/config";
import { RESPONSE_STATUS, STORAGE } from "@/constants";
import { auth, envIsProd, storage } from "@/helpers/index.ts";

const appDefaultConfig = config;
export default function setupAxiosInterceptors(
    navigate: NavigateFunction,
    logoutUser: () => void
) {
    // Add a request interceptor
    window.axios.interceptors.request.use(
        function (config) {
            // Do something before request is sent
            if (auth()?.isLogin && auth()?.tokenData) {
                config.headers.Authorization = `Bearer ${auth()?.tokenData
                    .accessToken}`;
            }

            const clientConfig = storage().getItem(STORAGE.CLIENT_CONFIG);
            config.headers.locale = clientConfig
                ? JSON.parse(clientConfig).locale
                : appDefaultConfig.locale;
            return config;
        },
        function (error) {
            // Do something with request error
            return Promise.reject(error);
        }
    );

    // Add a response interceptor
    window.axios.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        function (error) {
            const status = error?.response?.status;
            const redirectStatus = [
                RESPONSE_STATUS.BAD_REQUEST,
                RESPONSE_STATUS.FORBIDDEN,
                RESPONSE_STATUS.METHOD_NOT_ALLOWED,
                RESPONSE_STATUS.INTERNAL_SERVER_ERROR
            ];

            if (status === RESPONSE_STATUS.UNAUTHORIZED) logoutUser();

            if (redirectStatus.includes(status) && envIsProd()) {
                navigate(`/${status}`, {
                    state: {
                        previousUrl: window.location.pathname
                    }
                });
            }

            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            // eslint-disable-next-line no-console
            if (envIsProd()) console.clear();
            return Promise.reject(error);
        }
    );
}
