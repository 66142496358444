import { useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/store";

const useAuth = () => {
    const {
        user,
        tokenData,
        isLogin: userIsLogin
    } = useSelector((state: RootState) => state.auth);

    const isLogin = useMemo(() => {
        if (!userIsLogin && !tokenData) return false;
        const expiresAt = tokenData?.expires_at;

        return !(
            window.dayjs(expiresAt).isValid() &&
            window.dayjs().isAfter(expiresAt)
        );
    }, [tokenData, userIsLogin]);

    return {
        isLogin,
        user,
        tokenData: tokenData
    };
};

export default useAuth;
