import HeLockClosedIcon from "@icon/HeLockClosedIcon.tsx";
import HeLockOpenIcon from "@icon/HeLockOpenIcon.tsx";
import React, {
    ForwardedRef,
    forwardRef,
    InputHTMLAttributes,
    useMemo,
    useState
} from "react";

import { cn } from "@/libs/style.ts";

export interface InputPropsType extends InputHTMLAttributes<HTMLInputElement> {
    error?: string | string[];
    icon?: React.ReactNode;
    iconRight?: boolean;
    variant?: "default" | "secondary";
    inputClassName?: string;
}

const Input = forwardRef(
    (props: InputPropsType, ref: ForwardedRef<HTMLInputElement>) => {
        const {
            error,
            icon,
            variant = "default",
            iconRight,
            className,
            inputClassName,
            type = "text",
            ...rest
        } = props;

        const [show, setShow] = useState(false);

        const variantStyle = useMemo(() => {
            return cn({
                "border-gray-300 placeholder-gray-400": variant === "default",
                "focus:border-blue-600 focus:ring focus:ring-blue-600/20":
                    variant === "default",
                "disabled:bg-gray-50 disabled:text-gray-400":
                    variant === "default",
                "bg-gray-100 border-none hover:border-none ring-0":
                    variant === "secondary",
                "focus:border-none focus:ring-0 text-gray-500":
                    variant === "secondary"
            });
        }, [variant]);

        if (icon) {
            return (
                <div className={cn("relative", className)}>
                    <span
                        data-testid="icon-container"
                        className={cn({
                            "absolute top-[1.2rem]": true,
                            "right-4": iconRight,
                            "left-4": !iconRight
                        })}
                    >
                        {icon}
                    </span>

                    <input
                        ref={ref}
                        {...rest}
                        className={cn({
                            "w-full rounded-md": true,
                            "mt-2 px-4 py-2.5 text-sm": true,
                            "disabled:cursor-not-allowed": true,
                            [variantStyle]: true,
                            "pr-12": iconRight,
                            "pl-12": !iconRight,
                            "border-red-500 focus:border-red-500 focus:ring-red-500/20":
                                !!error,
                            [inputClassName ?? ""]: true
                        })}
                    />

                    {!!error && (
                        <p className="absolute top-0 text-sm text-red-600">
                            {error}
                        </p>
                    )}
                </div>
            );
        }

        return (
            <div className={cn("relative w-full", className)}>
                <input
                    ref={ref}
                    type={
                        type === "password"
                            ? show
                                ? "text"
                                : "password"
                            : type
                    }
                    {...rest}
                    className={cn({
                        "w-full rounded-md": true,
                        "mt-2 px-4 py-2.5 text-sm": true,
                        "disabled:cursor-not-allowed": true,
                        [variantStyle]: true,
                        "border-red-500 focus:border-red-500 focus:ring-red-500/20":
                            !!error,
                        [inputClassName || ""]: true
                    })}
                />

                {type === "password" && (
                    <button
                        type="button"
                        className={cn({
                            "absolute h-full right-0 top-0 px-8 md:px-4": true,
                            "focus:outline-none text-gray-500": true,
                            "-top-4": !!error
                        })}
                        onClick={() => setShow(!show)}
                    >
                        {show ? (
                            <HeLockOpenIcon className="text-gray-400" />
                        ) : (
                            <HeLockClosedIcon className="text-gray-400" />
                        )}
                    </button>
                )}

                {!!error && (
                    <p
                        className={cn({
                            "relative text-sm text-red-600": true,
                            absolute: type !== "password"
                        })}
                    >
                        {error}
                    </p>
                )}
            </div>
        );
    }
);

Input.displayName = "Input";

export default Input;
