import { ReactNode, useState } from "react";

import { AppLayout } from "@/components/layouts";
import { cn } from "@/libs/style.ts";
import Logs from "@/pages/admin/components/Logs.tsx";
import Users from "@/pages/admin/components/Users.tsx";

interface Props {
    isActive?: boolean;
    onClick: () => void;
    children?: ReactNode;
}

const TabButton = (props: Props) => {
    const { isActive, onClick, ...others } = props;

    return (
        <button
            className={cn(
                "px-2 py-2 focus:outline-none border-b -mb-px",
                isActive && "border-blue-600 text-blue-600 font-bold"
            )}
            onClick={onClick}
            {...others}
        />
    );
};

const Admin = () => {
    const [tab, setTab] = useState<"users" | "logs">("users");

    return (
        <AppLayout>
            <div className="flex border-b mb-5">
                <TabButton
                    isActive={tab === "users"}
                    onClick={() => setTab("users")}
                >
                    Gestion des utilisateurs
                </TabButton>

                <TabButton
                    isActive={tab === "logs"}
                    onClick={() => setTab("logs")}
                >
                    Gestion des logs
                </TabButton>
            </div>

            {tab === "users" && <Users />}

            {tab === "logs" && <Logs />}
        </AppLayout>
    );
};

export default Admin;
