import { ReactNode } from "react";

import { cn } from "@/libs/style.ts";

interface Props {
    children: ReactNode;
    title: string;
    description?: ReactNode;
}

const AuthLayout = (props: Props) => {
    const { children, title, description } = props;

    return (
        <div className="relative overflow-hidden min-h-[550px] h-screen bg-[#FAFBFC] px-9 pb-9 pt-24">
            <div className="absolute shadow-sm z-10 top-0 left-0 right-0 flex items-center justify-start w-full h-16 px-9 bg-white">
                <div className="inline-block bg-[#0070B2] rounded-full">
                    <img
                        src="/images/logo.png"
                        alt=""
                        className="object-cover object-center w-[50px]"
                    />
                </div>
            </div>

            <img
                className="absolute z-0 -top-[70rem] left-[50%]"
                src="/images/login-left.svg"
                alt=""
            />

            <div className="border rounded-md border-gray-100 flex items-center w-full h-full bg-white">
                <div className="flex items-center justify-center w-5/12">
                    <div className="w-2/3 h-full">
                        <h3
                            className={cn({
                                "text-2xl mb-5 font-bold": true,
                                "mb-2": !!description
                            })}
                        >
                            {title}
                        </h3>

                        {description && <p className="mb-5">{description}</p>}

                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthLayout;
