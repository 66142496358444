import ENDPOINTS from "./endpoints.ts";
import PAGES from "./pages.ts";

const ENV_PREFIX = "VITE_";

const RESPONSE_STATUS = {
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    CONFLICT: 409,
    INTERNAL_SERVER_ERROR: 500,
    UNPROCESSABLE_ENTITY: 422
};

const STORAGE_KEYS = {
    CLIENT_CONFIG: "clientConfig"
};

const DEFAULT_DATE_FR_FORMAT = "DD-MM-YYYY";

const DATE_TIME_FR_FORMAT = "DD-MM-YYYY HH:mm:ss";
const SECOND_DATE_TIME_FR_FORMAT = "DD MMM YYYY HH:mm";

const DATE_TIME_WITHOUT_SECOND_FR_FORMAT = "DD-MM-YYYY HH:mm";

const INPUT_DATE_FORMAT = "YYYY-MM-DD";

export const FILE_NAME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const SIMPLE_DATE_FORMAT = "DD MMM YYYY";
export const SIMPLE_DATE_FORMAT_FULL_MONTH = "DD MMMM YYYY";
export const SIMPLE_DATE_FORMAT_HOUR = "DD MMM YYYY HH:mm";
export const SIMPLE_DATE_FORMAT_HOUR_CUSTOM = "DD MMM YYYY à HH:mm";

const REGEX = {
    PIN: /^[0-9]{4}$/,
    NUMBER: /^[0-9]*$/,
    CHARACTER: /\D/g
};

export const ENV = {
    PROD: "prod",
    DEV: "dev"
};

export const STORAGE = {
    AUTH: "auth",
    CLIENT_CONFIG: "clientConfig",
    ACTIVITY: "activity"
};

export const DATATABLE = {
    PAGE_SIZE: 10,
    PAGE_INDEX: 0
};

export {
    ENDPOINTS,
    PAGES,
    RESPONSE_STATUS,
    STORAGE_KEYS,
    DEFAULT_DATE_FR_FORMAT,
    SECOND_DATE_TIME_FR_FORMAT,
    DATE_TIME_FR_FORMAT,
    DATE_TIME_WITHOUT_SECOND_FR_FORMAT,
    INPUT_DATE_FORMAT,
    REGEX,
    ENV_PREFIX
};

export const FILE_EXTENSION = {
    IMAGE: ["jpeg", "jpg", "png", "gif", "webp"],
    PDF: "pdf"
};

export const PERMISSIONS = {
    REFUND_TRANSFER: "refund-transfer",
    ASK_TO_REFUND_TRANSFER: "ask-to-refund-transfer",
    LIST_TRANSFER: "list-transfer",
    DOWNLOAD_TRANSFER: "download-transfer",
    GENERATE_TRANSFER_FILE: "generate-transfer-file",
    LIST_TRANSFER_FILE: "list-transfer-file",
    DOWNLOAD_TRANSFER_FILE: "download-transfer-file",
    GENERATE_ACCOUNT_FILE: "generate-account-file",
    LIST_ACCOUNT_FILE: "list-account-file",
    DOWNLOAD_ACCOUNT_FILE: "download-account-file",
    LIST_KYC: "list-kyc",
    SHOW_KYC: "show-kyc",
    REJECT_KYC: "reject-kyc",
    VALIDATE_KYC: "validate-kyc",
    SUSPEND_KYC: "suspend-kyc",
    REVIEW_KYC: "review-kyc",
    ENABLE_DISABLE_CHANNELS: "enable-disable-channel",
    ENABLE_DISABLE_TRANSFER_CARD: "enable-disable-transfer-card",
    RESEND_TRANSFER: "retry-transfer",
    RETRY_TRANSFER: "authorize-retry-transfer",
    UPDATE_TRANSFER_STATUS: "change-status-transfer",
    SEARCH_USER_ACCOUNT: "check-user-by-phone-number-with-indicative"
};

export const ROLES = {
    SME: "SME",
    SUPER_ADMIN: "SuperAdmin",
    RISK: "Risk",
    FIN_OPS: "FinOps"
};

export const STATUS_ACCOUNT = {
    activated: "Actif",
    suspended: "Suspendu",
    deleted: "Supprimé"
};

export const STATUS_KYC = {
    rejected: "Rejeté",
    validated: "Validé",
    to_validate: "A valider",
    to_review: "A revoir",
    submit_review: "A vérifier"
};

export const KYC_TYPE = {
    TRANSFER: "transfer_kyc",
    CARD: "card_kyc",
    TRANSFER_CARD: "transfer_and_card_kyc"
};

export const COUNTRY = [
    {
        indicative: "+229",
        name: "Bénin",
        shortName: "bj"
    },
    {
        indicative: "+225",
        name: "Côte d'Ivoire",
        shortName: "ci"
    },
    /*{
        indicative: "+237",
        name: "Cameroun",
        shortName: "cm"
    },*/
    {
        indicative: "+221",
        name: "Sénégal",
        shortName: "sn"
    },
    {
        indicative: "+223",
        name: "Mali",
        shortName: "ml"
    },
    {
        indicative: "+228",
        name: "Togo",
        shortName: "tg"
    },
    {
        indicative: "+226",
        name: "Burkina-Faso",
        shortName: "bf"
    }
    /*{
        indicative: "+224",
        name: "Guinée",
        shortName: "gn"
    },
    {
        indicative: "+234",
        name: "Nigéria",
        shortName: "ng"
    }*/
];

export const DEFAULT_COUNTRY_INDICATIVE = "+229";
