import Input from "@ui/Input.tsx";
import { ChangeEvent } from "react";

interface Props {
    filter: string;
    onFilterChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const UsersFilters = (props: Props) => {
    const { filter, onFilterChange } = props;

    return (
        <>
            <h3 className="font-bold text-lg">Liste des utilisateurs</h3>

            <div className="relative w-4/12 mb-7">
                <Input
                    id="name"
                    type="text"
                    value={filter}
                    onChange={onFilterChange}
                    placeholder="Rechercher un utilisateur à partir de l’adresse email"
                />
            </div>
        </>
    );
};

export default UsersFilters;
