import HiChevronDownIcon from "@icon/HiChevronDownIcon.tsx";
import Loading from "@ui/Loading.tsx";
import Tab, { TabButton, TabItem } from "@ui/tab";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import Account from "./components/Account.tsx";
import CardList from "./components/CardList.tsx";
import PhoneNumberList from "./components/PhoneNumberList.tsx";
import RemoveList from "./components/RemoveList.tsx";

import { AppLayout } from "@/components/layouts";
import { ENDPOINTS, PAGES, RESPONSE_STATUS } from "@/constants";
import { updateAxiosBaseUrl } from "@/helpers";
import { cn } from "@/libs/style.ts";
import useToast from "@/libs/useToast.tsx";
import { ApiService, KycType } from "@/types";

const KycDetail = () => {
    const [kyc, setKyc] = useState<KycType | null>(null);
    const [loading, setLoading] = useState(true);

    const { id: kycId } = useParams();
    const navigate = useNavigate();

    const { error: errorToast } = useToast();

    useEffect(() => {
        if (kycId) {
            updateAxiosBaseUrl(ApiService.moc);
            setLoading(true);
            window.axios
                .get(ENDPOINTS.DETAILS_ACCOUNT.replace(":id", kycId))
                .then(response => {
                    const kycDetails = response.data.data as KycType;
                    setKyc(kycDetails || null);
                })
                .catch(error => {
                    if (error.response.status === RESPONSE_STATUS.NOT_FOUND) {
                        navigate(PAGES.NOT_FOUND);
                    } else {
                        errorToast("Echec de récupération du KYC");
                    }
                })
                .finally(() => setLoading(false));
        }
    }, [errorToast, kycId, navigate]);

    return (
        <AppLayout>
            <Tab activeIndex={1}>
                <div className="bg-white border rounded-md px-4 py-5 min-h-[calc(100vh-7rem)]">
                    <div className="flex items-center mb-8">
                        <button onClick={() => navigate(-1)} className="mr-3">
                            <HiChevronDownIcon
                                size="xl"
                                className="rotate-90"
                            />
                        </button>

                        <TabButton
                            as="button"
                            index={1}
                            className={isActive =>
                                cn({
                                    "px-4 py-2 text-lg font-medium": true,
                                    "border-b-4 border-transparent": true,
                                    "border-gray-600": isActive
                                })
                            }
                        >
                            Compte
                        </TabButton>

                        <TabButton
                            as="button"
                            index={2}
                            className={isActive =>
                                cn({
                                    "px-4 py-2 text-lg font-medium": true,
                                    "border-b-4 border-transparent": true,
                                    "border-gray-600": isActive
                                })
                            }
                        >
                            Numéros
                        </TabButton>

                        <TabButton
                            as="button"
                            index={3}
                            className={isActive =>
                                cn({
                                    "px-4 py-2 text-lg font-medium": true,
                                    "border-b-4 border-transparent": true,
                                    "border-gray-600": isActive
                                })
                            }
                        >
                            Cartes
                        </TabButton>

                        <TabButton
                            as="button"
                            index={4}
                            className={isActive =>
                                cn({
                                    "px-4 py-2 text-lg font-medium": true,
                                    "border-b-4 border-transparent": true,
                                    "border-gray-600": isActive
                                })
                            }
                        >
                            Suppression
                        </TabButton>
                    </div>

                    <TabItem index={1}>
                        {kyc && (
                            <Account
                                kyc={kyc}
                                onActionSuccess={kyc => setKyc(kyc)}
                            />
                        )}
                    </TabItem>

                    <TabItem index={2}>
                        {kyc && <PhoneNumberList kyc={kyc} />}
                    </TabItem>

                    <TabItem index={3}>
                        {kyc && (
                            <CardList
                                kyc={kyc}
                                onSuccess={value =>
                                    setKyc(kyc => {
                                        if (kyc) {
                                            return {
                                                ...kyc,
                                                transfer_cards:
                                                    kyc.transfer_cards.map(
                                                        item => {
                                                            if (
                                                                item.id ===
                                                                value.id
                                                            )
                                                                return value;

                                                            return item;
                                                        }
                                                    )
                                            };
                                        }
                                        return null;
                                    })
                                }
                            />
                        )}
                    </TabItem>

                    <TabItem index={4}>
                        {kyc && <RemoveList kyc={kyc} />}
                    </TabItem>

                    {loading && (
                        <div className="py-12">
                            <Loading className="h-12 w-12 mx-auto" />
                        </div>
                    )}
                </div>
            </Tab>
        </AppLayout>
    );
};

export default KycDetail;
