import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DATATABLE, ENDPOINTS } from "../constants";
import { updateAxiosBaseUrl } from "../helpers";
import { RootState } from "../store";
import {
    selectKyc,
    updateKycFilterValue,
    updateKycListData
} from "../store/kycSlice.ts";
import { ApiService, FilterKycParamsType, KycType } from "../types";

const useManageKyc = () => {
    const [loading, setLoading] = useState(false);

    const { currentKyc, filterKycParams, kycListData } = useSelector(
        (state: RootState) => state.kyc
    );
    const dispatch = useDispatch();

    const refreshKycList = useCallback(() => {
        const start = filterKycParams.createdDate
            ? filterKycParams.createdDate.start || ""
            : "";
        const end = filterKycParams.createdDate
            ? filterKycParams.createdDate.end || ""
            : "";
        const transferValidateStart =
            filterKycParams.transferKycValidationDate?.start || "";
        const transferValidateEnd =
            filterKycParams.transferKycValidationDate?.end || "";

        const cardValidateStart =
            filterKycParams.cardKycValidationDate?.start || "";
        const cardValidateEnd =
            filterKycParams.cardKycValidationDate?.end || "";

        setLoading(true);
        updateAxiosBaseUrl(ApiService.moc);
        const page = filterKycParams.pageIndex
            ? filterKycParams.pageIndex
            : DATATABLE.PAGE_INDEX;
        const limit = filterKycParams.pageSize || DATATABLE.PAGE_SIZE;
        const statusKyc = filterKycParams.statusKyc;
        const statusKycTransfer = filterKycParams.statusKycTransfer;
        const statusKycCard = filterKycParams.statusKycCard;
        const cardFilter = filterKycParams.cardFilter;
        const indicative = filterKycParams.country;
        const statusAccount = filterKycParams.statusAccount;
        const ceiling = filterKycParams.ceiling;
        const params = {
            page: page + 1,
            limit: limit,
            search: filterKycParams.searchValue || "",
            status_kyc: statusKyc?.value || "",
            transfer_kyc_status: statusKycTransfer?.value || "",
            card_kyc_status: statusKycCard?.value || "",
            validated_at: filterKycParams.validationDate || "",
            created_at: {
                start_at: start,
                end_at: end
            },
            indicative: indicative?.value || "",
            status: statusAccount?.value || "",
            kyc_type: cardFilter?.value || "",
            transactional_limit: ceiling?.value || "",
            transfer_kyc_validated_at: {
                start_at: transferValidateStart,
                end_at: transferValidateEnd
            },
            card_kyc_validated_at: {
                start_at: cardValidateStart,
                end_start: cardValidateEnd
            },
            validated_by:
                filterKycParams.validatedBy?.map(item => item?.value || "") ||
                []
        };

        window.axios
            .get(ENDPOINTS.LIST_ACCOUNT, {
                params
            })
            .then(response => {
                const data = response.data?.data;

                if (data?.kycs || data?.total) {
                    dispatch(
                        updateKycListData({
                            currentPageData: data.kycs,
                            total: data?.total
                        })
                    );
                }
                setLoading(false);
            })
            .catch(() => {
                dispatch(
                    updateKycListData({
                        currentPageData: [],
                        total: 0
                    })
                );
            })
            .finally(() => setLoading(false));
    }, [
        dispatch,
        filterKycParams.cardFilter,
        filterKycParams.cardKycValidationDate?.end,
        filterKycParams.cardKycValidationDate?.start,
        filterKycParams.ceiling,
        filterKycParams.country,
        filterKycParams.createdDate,
        filterKycParams.pageIndex,
        filterKycParams.pageSize,
        filterKycParams.searchValue,
        filterKycParams.statusAccount,
        filterKycParams.statusKyc,
        filterKycParams.statusKycCard,
        filterKycParams.statusKycTransfer,
        filterKycParams.transferKycValidationDate?.end,
        filterKycParams.transferKycValidationDate?.start,
        filterKycParams.validatedBy,
        filterKycParams.validationDate
    ]);

    const updateKycFilter = useCallback(
        (value: FilterKycParamsType) => {
            dispatch(updateKycFilterValue({ ...filterKycParams, ...value }));
        },
        [dispatch, filterKycParams]
    );

    return {
        isOpen: !!currentKyc,
        kyc: currentKyc,
        openModal: useCallback(
            (kyc: KycType) => {
                dispatch(selectKyc(kyc));
            },
            [dispatch]
        ),
        closeModal: useCallback(() => {
            dispatch(selectKyc(null));
        }, [dispatch]),
        refreshKyc: useCallback(
            (kyc?: KycType) => {
                refreshKycList();
                kyc && dispatch(selectKyc(kyc));
            },
            [dispatch, refreshKycList]
        ),
        loading,
        kycListData,
        refreshKycList,
        updateKycFilter,
        filterKycParams
    };
};

export default useManageKyc;
