import Button from "@ui/Button.tsx";
import Input from "@ui/Input.tsx";
import { useCallback, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";

import AuthLayout from "./layouts/AuthLayout";

import { ENDPOINTS, PAGES, RESPONSE_STATUS } from "@/constants";
import { updateAxiosBaseUrl } from "@/helpers";
import useManageSchemaValidationError from "@/hooks/useManageSchemaValidationError.ts";
import useToast from "@/libs/useToast.tsx";
import rules from "@/schemas";
import { ApiService } from "@/types";

const ResetPasswordSchema = yup.object().shape({
    newPassword: rules.password,
    password: rules.confirmPassword("newPassword")
});

const INVALIDE_TOKEN_KEY = "token";

const ResetPassword = () => {
    const [loading, setLoading] = useState(false);
    const [invalidToken, setInvalidToken] = useState(false);
    const [formData, setFormData] = useState({
        newPassword: "",
        password: ""
    });

    const { customSuccess: successToast, customError: errorToast } = useToast();
    const navigate = useNavigate();
    const { errors, setErrors, resetErrors, showErrors } =
        useManageSchemaValidationError();
    const [searchParams] = useSearchParams();

    const handleSubmit = useCallback(() => {
        ResetPasswordSchema.validate(formData, { abortEarly: false })
            .then(() => {
                function resetInvalidTokenAlert() {
                    invalidToken && setInvalidToken(false);
                }

                setLoading(true);
                updateAxiosBaseUrl(ApiService.auth);
                const sendData = {
                    password: formData.password,
                    token: searchParams.get("token")
                };
                window.axios
                    .post(ENDPOINTS.RESET_PASSWORD, sendData)
                    .then(() => {
                        resetInvalidTokenAlert();
                        resetErrors();
                        successToast({
                            message:
                                "Votre mot de passe a été réinitialisé avec succès"
                        });
                        navigate(PAGES.LOGIN);
                    })
                    .catch(error => {
                        resetInvalidTokenAlert();
                        resetErrors();
                        const status = error.response.status;
                        const errorResponse = error.response.data.errors;
                        if (status === RESPONSE_STATUS.UNPROCESSABLE_ENTITY) {
                            if (
                                Object.keys(errorResponse).includes(
                                    INVALIDE_TOKEN_KEY
                                )
                            ) {
                                setInvalidToken(true);
                            } else {
                                setErrors(errorResponse);
                            }
                        }
                        errorToast({
                            message: "Echec de réinitialisation du mot de passe"
                        });
                    })
                    .finally(() => setLoading(false));
            })
            .catch(showErrors());
    }, [
        errorToast,
        formData,
        invalidToken,
        navigate,
        resetErrors,
        searchParams,
        setErrors,
        showErrors,
        successToast
    ]);

    return (
        <AuthLayout title="Réinitialisez votre mot de passe">
            {invalidToken && (
                <p className="p-2.5 text-xs text-red-800 bg-red-100 rounded-md text-center mb-5">
                    Le token est invalide ou déjà expiré.
                </p>
            )}

            <Input
                type="password"
                placeholder="Nouveau mot de passe"
                className="mb-4"
                value={formData.newPassword}
                onChange={e =>
                    setFormData({ ...formData, newPassword: e.target.value })
                }
                error={errors?.newPassword}
            />

            <Input
                type="password"
                placeholder="Nouveau mot de passe"
                className="mb-8"
                onChange={e =>
                    setFormData({ ...formData, password: e.target.value })
                }
                error={errors?.password}
            />

            <Button onClick={handleSubmit} loading={loading}>
                Modifier
            </Button>

            <div className="text-center mt-2">
                <NavLink
                    className="text-blue-600 hover:underline text-sm inline-block"
                    to={PAGES.LOGIN}
                >
                    Je me souviens
                </NavLink>
            </div>
        </AuthLayout>
    );
};

export default ResetPassword;
