const PAGES = {
    HOME: "/",
    NOT_FOUND: "/404",
    TRANSFERS: "/transfers",
    TRANSFER_DETAILS: "/transfers/:id",
    TRANSFER_SEARCH: "/transfers/search",
    TRANSFERS_FILES: "/transfers-files",
    ACCOUNTS: "/accounts",
    LOGIN: "/login",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password",
    REGISTER: "/register",
    RISK: "/risk",
    RISK_DETAILS: "/risk/:id",
    NOTIFICATION: "/notifications",
    WALLETS: "/wallets",
    ACCOUNT_SEARCH: "/account/search",
    ACCOUNT_PICTURE: "/picture/:id/:key",
    ADMIN: "/admin",
    VALIDATE_OTP: "/2fa-verify",
    CHECK_TEMPORARY_TOKEN: "/set-password"
};

export default PAGES;
