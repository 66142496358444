import Button from "@ui/Button.tsx";
import Input from "@ui/Input.tsx";
import Loading from "@ui/Loading.tsx";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";

import { ENDPOINTS, PAGES, RESPONSE_STATUS } from "@/constants";
import { updateAxiosBaseUrl } from "@/helpers";
import useManageSchemaValidationError from "@/hooks/useManageSchemaValidationError.ts";
import useToast from "@/libs/useToast.tsx";
import AuthLayout from "@/pages/auth/layouts/AuthLayout.tsx";
import rules from "@/schemas";
import { ApiService } from "@/types";

const INVALID_TOKEN_KEY = "token";

const ResetPasswordSchema = yup.object().shape({
    newPassword: rules.password,
    password: rules.confirmPassword("newPassword")
});

const CheckTemporaryCode = () => {
    const [loading, setLoading] = useState(true);
    const [invalidToken, setInvalidToken] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        newPassword: "",
        password: ""
    });

    const { customSuccess: successToast, customError: errorToast } = useToast();
    const navigate = useNavigate();
    const { errors, setErrors, resetErrors, showErrors } =
        useManageSchemaValidationError();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const token = searchParams.get("token");
        const email = searchParams.get("email");

        if (token && email) {
            updateAxiosBaseUrl(ApiService.auth);
            window.axios
                .post(ENDPOINTS.CHECK_TEMPORARY_TOKEN, { token, email })
                .catch(error => {
                    const status = error.response.status;
                    const { key } = error.response.data.output;

                    if (status === RESPONSE_STATUS.UNPROCESSABLE_ENTITY) {
                        errorToast({
                            message: "Email ou token invalide"
                        });
                    }

                    if (status === RESPONSE_STATUS.CONFLICT && key) {
                        if (key === "temporary_token_expired") {
                            errorToast({
                                message: "Le token est déjà utilisé ou expiré"
                            });
                        }

                        if (key === "temporary_token_not_correct") {
                            errorToast({
                                message: "Le token est invalide"
                            });
                        }
                    }

                    navigate(PAGES.LOGIN);
                })
                .finally(() => setLoading(false));
        } else {
            errorToast({
                message: "Le token ou l'email sont requis"
            });
            navigate(PAGES.LOGIN);
        }
    }, [errorToast, navigate, searchParams, successToast]);

    const handleSubmit = useCallback(
        (e: FormEvent) => {
            e.preventDefault();
            ResetPasswordSchema.validate(formData, { abortEarly: false })
                .then(() => {
                    function resetInvalidTokenAlert() {
                        invalidToken && setInvalidToken(false);
                    }

                    setSubmitting(true);
                    updateAxiosBaseUrl(ApiService.auth);
                    const sendData = {
                        password: formData.password,
                        token: searchParams.get("token")
                    };
                    window.axios
                        .post(ENDPOINTS.RESET_PASSWORD, sendData)
                        .then(() => {
                            resetInvalidTokenAlert();
                            resetErrors();
                            successToast({
                                message: "Mot de passe modifier avec succès"
                            });
                            navigate(PAGES.LOGIN);
                        })
                        .catch(error => {
                            resetInvalidTokenAlert();
                            resetErrors();
                            const status = error.response.status;
                            const errorResponse = error.response.data.errors;

                            if (
                                status === RESPONSE_STATUS.UNPROCESSABLE_ENTITY
                            ) {
                                if (
                                    Object.keys(errorResponse).includes(
                                        INVALID_TOKEN_KEY
                                    )
                                ) {
                                    setInvalidToken(true);
                                } else {
                                    setErrors(errorResponse);
                                }
                            }

                            errorToast({
                                message: "Echec de modification du mot de passe"
                            });
                        })
                        .finally(() => setSubmitting(false));
                })
                .catch(showErrors());
        },
        [
            errorToast,
            formData,
            invalidToken,
            navigate,
            resetErrors,
            searchParams,
            setErrors,
            showErrors,
            successToast
        ]
    );

    return (
        <>
            <AuthLayout title="Créer votre mot de passe">
                {invalidToken && (
                    <p className="p-2.5 text-xs text-red-800 bg-red-100 rounded-md text-center mb-5">
                        Le token est invalide ou déjà expiré.
                    </p>
                )}

                <Input
                    type="password"
                    placeholder="Nouveau mot de passe"
                    className="mb-4"
                    value={formData.newPassword}
                    onChange={e =>
                        setFormData({
                            ...formData,
                            newPassword: e.target.value
                        })
                    }
                    error={errors?.newPassword}
                />

                <Input
                    type="password"
                    placeholder="Nouveau mot de passe"
                    className="mb-8"
                    onChange={e =>
                        setFormData({ ...formData, password: e.target.value })
                    }
                    error={errors?.password}
                />

                <Button
                    onClick={handleSubmit}
                    loading={submitting}
                    disabled={submitting}
                >
                    Enregistrer
                </Button>
            </AuthLayout>

            {loading && (
                <div className="absolute top-0 left-0 w-full bg-white z-50 h-screen flex items-center justify-center">
                    <Loading className="h-12 w-12 text-blue-600" />
                </div>
            )}
        </>
    );
};

export default CheckTemporaryCode;
