import { cn } from "@/libs/style.ts";
import { IconProps } from "@/types";

const BiSolidUserIcon = ({ size = "md", color, className = "" }: IconProps) => {
    return (
        <svg
            stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            className={cn({
                "text-green-500": color === "green",
                "text-yellow-500": color === "yellow",
                "text-red-500": color === "red",
                "h-3 w-3": size === "xs",
                "h-4 w-4": size === "sm",
                "h-5 w-5": size === "md",
                "h-7 w-7": size === "lg",
                "h-8 w-8": size === "xl",
                [className]: true
            })}
        >
            <circle cx="12" cy="12" r="10"></circle>
            <rect x="9" y="9" width="6" height="6" rx="1"></rect>
        </svg>
    );
};

export default BiSolidUserIcon;
