import Button from "@ui/Button.tsx";
import Input from "@ui/Input.tsx";
import { ChangeEvent, FormEvent, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ENDPOINTS, REGEX } from "@/constants";
import { envIsDev, getUrlAfterLogin, updateAxiosBaseUrl } from "@/helpers";
import useAuth from "@/hooks/useAuth.ts";
import useLogout from "@/hooks/useLogout.ts";
import { cn } from "@/libs/style.ts";
import useToast from "@/libs/useToast.tsx";
import AuthLayout from "@/pages/auth/layouts/AuthLayout.tsx";
import { setStoreVerifyOtp } from "@/store/authSlice.ts";
import { ApiService } from "@/types";

const TwoFactorAuth = () => {
    const [loading, setLoading] = useState(false);
    const [codeSending, setCodeSending] = useState(false);
    const [code, setCode] = useState("");

    const { user } = useAuth();
    const { logout, loading: logoutLoading } = useLogout();
    const {
        customSuccess: successToast,
        customError: errorToast,
        loading: toastLoading
    } = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value.length > 6) return;

        const regex = new RegExp(REGEX.NUMBER);
        if (!regex.test(value)) return;

        setCode(e.target.value);
    }, []);

    const handleResendCode = useCallback(() => {
        const toastId = toastLoading("Code en cours d'envoi...");
        setCodeSending(true);
        updateAxiosBaseUrl(ApiService.auth);
        window.axios
            .post(ENDPOINTS.SEND_2FA_CODE)
            .then(() => {
                successToast(
                    { message: "Code envoyer avec succès" },
                    { id: toastId }
                );
            })
            .catch(() => {
                errorToast(
                    { message: "Echec d'envoie du code" },
                    { id: toastId }
                );
            })
            .finally(() => {
                setCodeSending(false);
            });
    }, [errorToast, successToast, toastLoading]);

    const handleSubmit = useCallback(
        (e: FormEvent) => {
            e.preventDefault();
            setLoading(true);
            updateAxiosBaseUrl(ApiService.auth);
            window.axios
                .post(ENDPOINTS.VERIFY_2FA_CODE, { two_factor_code: code })
                .then(() => {
                    successToast({ message: "Code vérifier avec succès" });
                    dispatch(setStoreVerifyOtp(true));
                    setTimeout(() => {
                        navigate(getUrlAfterLogin());
                    }, 20);
                })
                .catch(() => {
                    errorToast({ message: "Echec de vérification du code" });
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [code, dispatch, errorToast, navigate, successToast]
    );

    return (
        <AuthLayout
            title="Saisissez le code reçu par émail"
            description={
                <>
                    Nous avons envoyé un code à 4 chiffres à l’adresse{" "}
                    <strong>{user?.email}</strong>.
                </>
            }
        >
            <form onSubmit={handleSubmit}>
                <Input
                    type="text"
                    placeholder="Code"
                    value={code}
                    onChange={handleCodeChange}
                    required
                />

                <button
                    className={cn(
                        "text-blue-600 my-5",
                        codeSending && "cursor-not-allowed"
                    )}
                    type="button"
                    onClick={handleResendCode}
                    disabled={codeSending}
                >
                    Renvoyer le code
                </button>

                <Button
                    loading={loading}
                    type="submit"
                    disabled={codeSending || loading}
                >
                    Vérifier
                </Button>
            </form>

            {envIsDev() && (
                <Button
                    className="fixed shadow-sm bottom-14 left-16 z-50 bg-white"
                    variant="gray"
                    size="sm"
                    withAuto
                    loading={logoutLoading}
                    onClick={() => logout("Succès de la déconnexion")}
                >
                    Déconnexion
                </Button>
            )}
        </AuthLayout>
    );
};

export default TwoFactorAuth;
