import { useEffect, useState } from "react";

const useDebounceValue = (value: string, timer = 350) => {
    const [debounceValue, setDebounceValue] = useState(value);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebounceValue(value);
        }, timer);

        return () => {
            clearTimeout(timeout);
        };
    }, [timer, value]);

    return debounceValue;
};

export default useDebounceValue;
