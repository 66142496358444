import Button from "@ui/Button.tsx";
import Input from "@ui/Input.tsx";
import { FormEvent, useCallback, useEffect, useState } from "react";
import * as yup from "yup";

import HeaderContainer from "@/components/HeaderContainer.tsx";
import ValidatedBySelect from "@/components/ValidatedBySelect.tsx";
import { ENDPOINTS, RESPONSE_STATUS } from "@/constants";
import { updateAxiosBaseUrl } from "@/helpers";
import useAuth from "@/hooks/useAuth.ts";
import useManageSchemaValidationError from "@/hooks/useManageSchemaValidationError.ts";
import { cn } from "@/libs/style.ts";
import useToast from "@/libs/useToast.tsx";
import rules from "@/schemas";
import { ApiService } from "@/types";

interface Props {
    onCreateSuccess?: () => void;
}

interface RoleType {
    value: string;
    label: string;
}

const CreateUserSchema = yup.object().shape({
    full_name: rules.fullName,
    email: rules.email,
    roles: yup
        .array()
        .of(rules.role)
        .min(1, "Vous devez sélectionner au moins un rôle.")
        .required("Le champ rôles est obligatoire.")
});

const CreateUserForm = (props: Props) => {
    const { onCreateSuccess } = props;
    const [roleList, setRoleList] = useState<RoleType[]>([]);
    const [roles, setRoles] = useState<RoleType[]>([]);
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");

    const { errors, setErrors, resetErrors, showErrors } =
        useManageSchemaValidationError();

    const { user } = useAuth();
    const { customSuccess: successToast, customError: errorToast } = useToast();

    useEffect(() => {
        updateAxiosBaseUrl(ApiService.moc);
        window.axios.get(ENDPOINTS.ROLES).then(({ data }) => {
            const roles = data.data["user-role"];
            if (Array.isArray(roles)) {
                setRoleList(
                    roles.map(item => ({
                        value: item.name,
                        label: item.name
                    }))
                );
            }
        });
    }, []);

    const [loading, setLoading] = useState(false);

    const handleSubmit = useCallback(
        (e: FormEvent) => {
            e.preventDefault();
            if (user) {
                const sendData = {
                    full_name: fullName,
                    email,
                    roles: roles.map(item => item.value)
                };
                CreateUserSchema.validate(sendData, { abortEarly: false })
                    .then(() => {
                        setLoading(true);
                        updateAxiosBaseUrl(ApiService.moc);
                        window.axios
                            .post(ENDPOINTS.CREATE_USER, sendData)
                            .then(() => {
                                resetErrors();
                                setRoles([]);
                                setFullName("");
                                setEmail("");
                                onCreateSuccess && onCreateSuccess();
                                successToast({
                                    message: "Utilisateur créer avec succès"
                                });
                            })
                            .catch(error => {
                                resetErrors();
                                const status = error.response?.status;
                                const errors = error.response.data.errors;
                                if (
                                    status ===
                                    RESPONSE_STATUS.UNPROCESSABLE_ENTITY
                                ) {
                                    setErrors(errors);
                                }
                                errorToast({
                                    message:
                                        "Echec de la création de l'utilisateur"
                                });
                            })
                            .finally(() => setLoading(false));
                    })
                    .catch(showErrors());
            }
        },
        [
            email,
            errorToast,
            fullName,
            onCreateSuccess,
            resetErrors,
            roles,
            setErrors,
            showErrors,
            successToast,
            user
        ]
    );

    return (
        <HeaderContainer className="mb-5 py-7">
            <h2 className="text-xl font-bold mb-4">
                Formulaire d’ajout d’un utilisateur
            </h2>

            <form onSubmit={handleSubmit} className="flex items-end gap-5">
                <div className="flex-1">
                    <label htmlFor="name" className="text-sm text-gray-500">
                        Nom du collaborateur
                    </label>

                    <Input
                        className="w-full"
                        id="name"
                        type="text"
                        value={fullName}
                        onChange={e => setFullName(e.target.value)}
                        error={errors.full_name}
                    />
                </div>

                <div className="flex-1">
                    <label htmlFor="email" className="text-sm text-gray-500">
                        Email
                    </label>

                    <Input
                        className="w-full"
                        id="email"
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        error={errors.email}
                    />
                </div>

                <div className="flex-1 relative">
                    <label htmlFor="role" className="text-sm text-gray-500">
                        Rôle
                    </label>

                    <ValidatedBySelect
                        containerClassName="w-full"
                        value={roles}
                        onValueChange={values => setRoles(values)}
                        options={roleList}
                        error={errors.roles?.length > 0}
                    />

                    {!!errors.roles && (
                        <p
                            className={cn({
                                "absolute text-sm text-red-600": true
                            })}
                        >
                            {errors.roles}
                        </p>
                    )}
                </div>

                <Button
                    loading={loading}
                    disabled={loading}
                    type="submit"
                    className="w-[17%]"
                >
                    Créer l{"'"}utilisateur
                </Button>
            </form>
        </HeaderContainer>
    );
};

export default CreateUserForm;
