import PlayIcon from "@icon/PlayIcon.tsx";
import StopIcon from "@icon/StopIcon.tsx";
import TrashIcon from "@icon/TrashIcon.tsx";
import { createColumnHelper } from "@tanstack/table-core";
import Button from "@ui/Button.tsx";
import Datatable from "@ui/datatable";
import Modal, { useModal } from "@ui/modal";
import Tooltip from "@ui/Tooltip.tsx";
import {
    ChangeEvent,
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import toast from "react-hot-toast";

import { DATATABLE, ENDPOINTS } from "@/constants";
import { updateAxiosBaseUrl } from "@/helpers";
import useAuth from "@/hooks/useAuth.ts";
import useDebounceValue from "@/hooks/useDebounceValue.ts";
import { cn } from "@/libs/style.ts";
import CreateUserForm from "@/pages/admin/components/CreateUserForm.tsx";
import UsersFilters from "@/pages/admin/components/UsersFilters.tsx";
import { ApiService, ModalPropsType, UserType } from "@/types";

type FormatUserType = {
    showPermissions: string[];
    otherPermissions: string[];
} & UserType;

type ActionType = "active" | "suspend" | "remove";

interface DataStateType {
    currentPageData: FormatUserType[];
    total: number;
}

interface FilterType {
    page: number;
    limit: number;
    email?: string;
}

interface PermissionsModalProps extends ModalPropsType {
    values: string[];
}

interface ConfirmModalProps extends ModalPropsType {
    onConfirm: () => void;
    loading: boolean;
}

const columnHelper = createColumnHelper<FormatUserType>();

const OtherPermissionsModal = (props: PermissionsModalProps) => {
    const { isOpen, closeModal, values } = props;

    return (
        <Modal isOpen={isOpen} closeModal={closeModal} className="px-10 pb-5">
            <div className="h-[300px] w-full overflow-y-scroll mt-5">
                {values.map((item, index) => (
                    <Fragment key={item}>
                        <p key={item} className="py-1 text-xs text-gray-700">
                            {item}
                        </p>

                        {index + 1 < values.length && <hr />}
                    </Fragment>
                ))}
            </div>
        </Modal>
    );
};

const ConfirmModal = (props: ConfirmModalProps) => {
    const { isOpen, closeModal, onConfirm, title, loading } = props;

    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeModal}
            className="max-w-[410px]"
        >
            <h2 className="text-lg font-bold text-center mt-8">Attention !!</h2>

            <p className="my-8 text-center">{title ?? "---"}</p>

            <div className="flex items-center justify-center space-x-4 mx-auto mb-12">
                <Button
                    variant="gray"
                    onClick={closeModal}
                    withAuto
                    disabled={loading}
                >
                    Annuler
                </Button>

                <Button
                    onClick={onConfirm}
                    withAuto
                    disabled={loading}
                    loading={loading}
                >
                    Confirmer
                </Button>
            </div>
        </Modal>
    );
};

const Logs = () => {
    const [data, setData] = useState<DataStateType>({
        currentPageData: [],
        total: 0
    });
    const [action, setAction] = useState<ActionType | null>(null);
    const [actionLoading, setActionLoading] = useState(false);
    const [permissions, setPermissions] = useState<string[]>([]);
    const [filter, setFilter] = useState("");
    const [{ pageIndex, pageSize }, setPaginate] = useState({
        pageIndex: DATATABLE.PAGE_INDEX,
        pageSize: DATATABLE.PAGE_SIZE
    });
    const [filterLoading, setFilterLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState<FormatUserType | null>(null);

    const { user } = useAuth();
    const debounceValue = useDebounceValue(filter);
    const {
        isOpen: perModalIsOpen,
        closeModal: closePerModal,
        openModal: perModalOpen
    } = useModal();
    const {
        isOpen: confirmIsOpen,
        closeModal: closeConfirmModal,
        openModal: confirmModalOpen
    } = useModal();

    const fetchData = useCallback((filter: FilterType) => {
        setFilterLoading(true);
        updateAxiosBaseUrl(ApiService.moc);
        if (!filter.email) delete filter.email;

        window.axios
            .get(ENDPOINTS.USERS, {
                params: filter
            })
            .then(response => {
                const { data } = response.data;
                const users = data["moc-user"] as UserType[];

                setData({
                    currentPageData: users.map(item => {
                        return {
                            ...item,
                            showPermissions: item.user_permissions
                                .splice(0, 2)
                                .map(item => item.description),
                            otherPermissions: item.user_permissions
                                .splice(2)
                                .map(item => item.description)
                        };
                    }),
                    total: data.total
                });
            })
            .catch(() => {
                setData({
                    currentPageData: [],
                    total: 0
                });
            })
            .finally(() => setFilterLoading(false));
    }, []);

    const applyFetchData = useCallback(() => {
        const email = debounceValue.length > 0 ? debounceValue : undefined;

        fetchData({
            email,
            limit: pageSize,
            page: pageIndex + 1
        });
    }, [debounceValue, fetchData, pageIndex, pageSize]);

    useEffect(() => {
        applyFetchData();
    }, [applyFetchData]);

    const handleFilterChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setPaginate({
                pageIndex: DATATABLE.PAGE_INDEX,
                pageSize: DATATABLE.PAGE_SIZE
            });
            setFilter(e.target.value);
        },
        []
    );

    const onAction = useCallback(
        (action: ActionType, data: FormatUserType) => {
            setAction(action);
            setCurrentUser(data);
            confirmModalOpen();
        },
        [confirmModalOpen]
    );

    const handleCloseConfirmModal = useCallback(() => {
        setAction(null);
        setCurrentUser(null);
        closeConfirmModal();
    }, [closeConfirmModal]);

    const handleConfirm = useCallback(() => {
        if (action && currentUser) {
            setActionLoading(true);
            let endpoint = "";
            const id = currentUser?.id.toString();

            if (action === "suspend") {
                endpoint = ENDPOINTS.USERS_SUSPEND.replace(":id", id);
            }

            if (action === "active") {
                endpoint = ENDPOINTS.USERS_ACTIVE.replace(":id", id);
            }

            if (action === "remove") {
                endpoint = ENDPOINTS.USERS_REMOVE.replace(":id", id);
            }

            updateAxiosBaseUrl(ApiService.moc);
            window.axios[action === "remove" ? "delete" : "put"](endpoint)
                .then(() => {
                    if (action === "suspend") {
                        toast.success(
                            "Succès de la suspenssion de l'utilisateur"
                        );
                    }

                    if (action === "remove") {
                        toast.success(
                            "Succès de la supression de l'utilisateur"
                        );
                    }

                    if (action === "active") {
                        toast.success(
                            "Succès de l'activation de l'utilisateur"
                        );
                    }

                    applyFetchData();
                    handleCloseConfirmModal();
                })
                .catch(() => {
                    if (action === "suspend") {
                        toast.error("Echec de suspenssion de l'utilisateur");
                    }

                    if (action === "remove") {
                        toast.error("Echec de supression de l'utilisateur");
                    }

                    if (action === "active") {
                        toast.error("Echec d'activation du compte");
                    }
                })
                .finally(() => setActionLoading(false));
        }
    }, [action, applyFetchData, currentUser, handleCloseConfirmModal]);

    const columns = useMemo(() => {
        return [
            columnHelper.accessor(row => row.email, {
                id: "email",
                header: () => "Email",
                cell: info => info.getValue(),
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.roles, {
                id: "roles",
                header: () => "Profil",
                cell: info => (
                    <>
                        {info.getValue().map((item, index) => (
                            <Fragment key={item.id + index}>
                                {item.name}
                                {index + 1 < info.getValue().length ? "; " : ""}
                            </Fragment>
                        ))}
                    </>
                ),
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.user_permissions, {
                id: "user_permissions",
                header: () => "Liste des accès",
                cell: info => {
                    const { showPermissions, otherPermissions } =
                        info.row.original;

                    return (
                        <>
                            {showPermissions.map((item, index) => (
                                <Fragment key={"permission-" + index}>
                                    {item}
                                    {index + 1 < showPermissions.length
                                        ? "; "
                                        : ""}
                                </Fragment>
                            ))}
                            {otherPermissions.length > 0 && (
                                <span
                                    role="button"
                                    onClick={() => {
                                        setPermissions(otherPermissions);
                                        perModalOpen();
                                    }}
                                    className="inline cursor-pointer text-blue-500 font-bold underline"
                                >
                                    {" "}
                                    et {otherPermissions.length} autre
                                    {otherPermissions.length > 1 && "s"}
                                </span>
                            )}
                        </>
                    );
                },
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.validated_at, {
                id: "validated_at",
                header: () => "Statut",
                cell: info => (
                    <span
                        className={cn({
                            "font-bold": true,
                            "text-red-500": !info.getValue(),
                            "text-green-500": info.getValue()
                        })}
                    >
                        {info.getValue() ? "Actif" : "Suspendu"}
                    </span>
                ),
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.validated_at, {
                id: "validated_at",
                header: () => "Action",
                cell: info => {
                    if (info.row.original.id === user?.id) return null;

                    return (
                        <div className="flex items-center space-x-1">
                            {info.getValue() ? (
                                <Tooltip content="Suspendre">
                                    <button
                                        onClick={() =>
                                            onAction(
                                                "suspend",
                                                info.row.original
                                            )
                                        }
                                        disabled={actionLoading}
                                        className={cn(
                                            actionLoading &&
                                                "cursor-not-allowed"
                                        )}
                                    >
                                        <StopIcon
                                            className="text-gray-400"
                                            size="sm"
                                        />
                                    </button>
                                </Tooltip>
                            ) : (
                                <Tooltip content="Activer">
                                    <button
                                        onClick={() =>
                                            onAction(
                                                "active",
                                                info.row.original
                                            )
                                        }
                                        disabled={actionLoading}
                                        className={cn(
                                            actionLoading &&
                                                "cursor-not-allowed"
                                        )}
                                    >
                                        <PlayIcon
                                            className="text-green-500"
                                            size="sm"
                                        />
                                    </button>
                                </Tooltip>
                            )}

                            <Tooltip content="Supprimer">
                                <button
                                    onClick={() =>
                                        onAction("remove", info.row.original)
                                    }
                                    disabled={actionLoading}
                                    className={cn(
                                        actionLoading && "cursor-not-allowed"
                                    )}
                                >
                                    <TrashIcon
                                        className="text-red-500"
                                        size="sm"
                                    />
                                </button>
                            </Tooltip>
                        </div>
                    );
                },
                footer: info => info.column.id
            })
        ];
    }, [actionLoading, onAction, perModalOpen, user?.id]);

    return (
        <>
            <CreateUserForm
                onCreateSuccess={() => {
                    setPaginate({
                        pageIndex: DATATABLE.PAGE_INDEX,
                        pageSize: DATATABLE.PAGE_SIZE
                    });
                    setFilter("");
                    applyFetchData();
                }}
            />

            <div className="bg-white border p-3 rounded-md mb-5">
                <UsersFilters
                    filter={filter}
                    onFilterChange={handleFilterChange}
                />

                <Datatable
                    columns={columns}
                    data={data.currentPageData}
                    allDataTotal={data.total}
                    manualFetchData
                    manualPaginateState={{
                        value: { pageIndex, pageSize },
                        setValue: setPaginate
                    }}
                    loading={filterLoading}
                    isSearchable={false}
                />

                <OtherPermissionsModal
                    isOpen={perModalIsOpen}
                    closeModal={() => {
                        closePerModal();
                        setPermissions([]);
                    }}
                    values={permissions}
                />

                <ConfirmModal
                    isOpen={confirmIsOpen}
                    loading={actionLoading}
                    title={cn({
                        "Etes-vous sûr de vouloir activer cet utilisateur?":
                            action === "active",
                        "Etes-vous sûr de vouloir suspendre cet utilisateur":
                            action === "suspend",
                        "Etes-vous sûr de vouloir supprimer cet utilisateur?":
                            action === "remove"
                    })}
                    closeModal={() => {
                        if (!actionLoading) {
                            handleCloseConfirmModal();
                        }
                    }}
                    onConfirm={handleConfirm}
                />
            </div>
        </>
    );
};

export default Logs;
