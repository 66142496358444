import { SelectValueType } from "@ui/select";
import { ReactNode, SVGAttributes } from "react";

export type SessionType = "localStorage" | "sessionStorage";

export enum ApiService {
    auth = "auth",
    transfer = "transfer",
    moc = "moc"
}

export enum PictureType {
    "png" = "image/png",
    "jpg" = "image/jpeg"
}

export type SizeType = "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl";

export interface IconProps extends SVGAttributes<SVGElement> {
    size?: SizeType;
    color?: "green" | "yellow" | "red";
}

export interface ChannelType {
    id: number;
    name: string;
    slug: string;
    code: string;
    phone_code: string;
    kind: string;
    available: number;
    available_at: string;
    has_otp: number;
    has_default: number;
    has_check_status: 1 | 0;
    tenant_id: number;
    created_at: string;
    updated_at: string;
    label: string;
    value: string;
    countryCode: string;
    country_id: number;
    countryIndicative: string;
}

export interface ModalPropsType {
    title?: string;
    isOpen?: boolean;
    closeModal: () => void;
    clickOutSideToClose?: boolean;
    hideCloseIcon?: boolean;
    children?: ReactNode;
    className?: string;
}

export interface User {
    id: number;
    full_name: string;
    phone_with_indicative: string;
    email: string | null;
    country_id: string | null;
    deleted_at: string | null;
    created_at: string | null;
    updated_at: string | null;
    photo_base64: string | null;
    photo: string | null;
    selfie_photo: string | null;
    deleted_reason?: string;
}

export enum PAY_IN_PAY_OUT_STATUS {
    SUCCESS = "success",
    FAILED = "failed"
}

export enum TRANSACTION_STATUS {
    TO_REFUND = "to_refund",
    REFUND = "refund",
    SUCCESS = "success",
    FAILED = "failed",
    PENDING = "pending"
}

export interface TransactionFileType {
    id: number;
    name: string;
    period: string;
    created_at: string;
    path: string;
    start_date: string;
    end_date: string;
}

export type AlertType = "warning" | "info" | "success" | "error";

export interface CountryType {
    id: number;
    name: string;
    slug: string;
    code: string;
    phone_code: string;
    currency: string;
    deleted_at: string | null;
    created_at: string | null;
    updated_at: string | null;
}

export interface SecondaryPhoneType {
    id: string;
    phone_with_indicative: string;
    type: string;
    temporary_code: string;
    code_expire_at: string;
    account_id: number;
    country_id: number;
    validated_at: string | null;
    activated_at: string;
    deleted_at: string | null;
    created_at: string;
    updated_at: string;
    account: KycType;
    country: CountryType;
}

interface RemoveAccountItem {
    id: string;
    account_id: number;
    account_created_at: string | null;
    account_deleted_at: string | null;
    deleted_reason: string;
    activated_at: string | null;
    deleted_at: string | null;
    created_at: string | null;
    updated_at: string | null;
}

export interface KycType {
    id: string;
    user_id: number;
    account_type: string;
    account_number: string;
    status: string;
    transfer_kyc_status: string | null;
    card_kyc_status: string | null;
    card_kyc_rejected_reason: string | null;
    suspended_at: string | null;
    suspension_reason: string | null;
    comments: string;
    transfer_kyc_rejected_at: string | null;
    transfer_kyc_rejected_reason: string | null;
    transfer_kyc_to_review_at: string | null;
    transfer_kyc_validated_at: string | null;
    card_kyc_rejected_at: string | null;
    card_kyc_to_review_at: string | null;
    card_kyc_validated_at: string | null;
    deleted_at: string | null;
    created_at: string;
    updated_at: string;
    transfer_phone_numbers: SecondaryPhoneType[];
    transfer_cards: CardType[];
    user: User;
    account_deleted_histories: RemoveAccountItem[];
    validated_by?: UserType;
}

export interface UserPermission {
    id: string;
    name: string;
    description: string;
    guard_name: string;
    created_at: string;
    updated_at: string;
    permission_id: string;
    model_type: string;
    model_id: string;
    role_id: string;
}

export interface UserType {
    id: number;
    full_name: string;
    phone_with_indicative: string | null;
    email: string;
    created_at: string;
    updated_at: string;
    temporary_code: string;
    roles: RoleType[];
    permissions: string[];
    photo: string | null;
    country_id: string | null;
    cgu_validated_at: string | null;
    selfie_photo: string | null;
    deleted_at: string | null;
    photo_base64: string | null;
    validated_at: string | null;
    user_limit_transactionnel: boolean;
    user_transfer_amount: number;
    deleted_reason?: string;
    user_permissions: PermissionType[];
    confirmOtp: boolean;
}

export interface RoleType {
    id: string;
    name: string;
    description: string;
    permissions: PermissionType[];
}

export interface PermissionType {
    id: string;
    name: string;
    description: string;
    guard_name: string;
    created_at: string;
    updated_at: string;
    permission_id: string;
    model_type: string;
    model_id: string;
}

export interface TokenDataType {
    id: string;
    accessToken: string;
    user_id: number;
    client_id: number;
    name: string;
    scopes: [];
    revoked: boolean;
    created_at: string;
    updated_at: string;
    expires_at: string;
}

export enum KycStatusType {
    rejected = "rejected",
    validated = "validated",
    to_validate = "to_validate",
    to_review = "to_review",
    submit_review = "submit_review"
}

export enum AccountStatusType {
    activated = "activated",
    actif = "actif",
    suspended = "suspended",
    deleted = "deleted"
}

export interface KycListDataType {
    currentPageData: KycType[];
    total: number;
}

export interface FilterKycParamsType {
    pageIndex?: number;
    pageSize?: number;
    searchValue?: string;
    createdDate?: {
        start?: string;
        end?: string;
    };
    validationDate?: string;
    transferKycValidationDate?: {
        start?: string;
        end?: string;
    };
    cardKycValidationDate?: {
        start?: string;
        end?: string;
    };
    statusKyc?: SelectValueType;
    statusKycTransfer?: SelectValueType;
    statusKycCard?: SelectValueType;
    cardFilter?: SelectValueType;
    statusAccount?: SelectValueType;
    country?: SelectValueType;
    ceiling?: SelectValueType;
    validatedBy?: { value: string; label: string }[];
}

export interface PaginateStateValue {
    pageIndex: number;
    pageSize: number;
}

export interface NotificationMessageType {
    text: string;
    key_correspondance: { [key: string]: string };
    action: string;
    id: number;
}

export interface NotificationType {
    id: string;
    type: string;
    notifiable_type: string;
    notifiable_id: number;
    data: NotificationMessageType;
    read_at: string | null;
    created_at: string | null;
    updated_at: string | null;
}

export interface EventParamType {
    defaultChannel: string;
    notification: NotificationType;
}

export interface AccountType {
    id: string;
    user_id: number;
    account_type: string;
    account_number: string;
    status: string;
    transfer_kyc_status: string | null;
    card_kyc_status: string | null;
    suspended_at: string | null;
    suspension_reason: string | null;
    comments: string;
    transfer_kyc_rejected_at: string | null;
    transfer_kyc_to_review_at: string | null;
    transfer_kyc_validated_at: string | null;
    card_kyc_rejected_at: string | null;
    card_kyc_to_review_at: string | null;
    card_kyc_validated_at: string | null;
    deleted_at: string | null;
    created_at: string;
    updated_at: string;
    user?: UserType;
    transfer_phone_numbers: SecondaryPhoneType[];
    transfer_cards: CardType[];
    can_send_card_kyc_relaunch: boolean;
    can_send_transfer_kyc_relaunch: boolean;
    transfer_kyc_rejected_reason: string | null;
    card_kyc_rejected_reason: string | null;
}

export interface CardType {
    id: string;
    name: string;
    type: string;
    account_id: number;
    country_id: number | null;
    updated_at: string | null;
    created_at: string | null;
    deleted_at: string | null;
    validated_at: string | null;
    activated_at: string | null;
    account: AccountType;
    country: CountryType;
    card_number: string;
}

export interface SuccessPayTransaction {
    id: number;
    identifier: string;
    type: string;
    status: string;
    channel_fees: string;
    marge: string;
    serviceable_type: string;
    serviceable_id: number;
    deleted_at: string | null;
    created_at: string | null;
    updated_at: string | null;
}

export interface PayChannel {
    id: number;
    name: string;
    slug: string;
    type: string;
    country_id: number;
    available_at: string;
    has_check_status: number;
    has_otp: number;
    has_default: number;
    deleted_at: string | null;
    created_at: string | null;
    updated_at: string | null;
}

export interface CashCall {
    id: number;
    token: string;
    serviceable_type: string;
    serviceable_id: number;
    status: string;
    sendPayloadResponse: string;
    completedResponse: string;
    deleted_at: string | null;
    created_at: string | null;
    updated_at: string | null;
}

export interface TransferType {
    id: number;
    user_id: number | null;
    user: User | null;
    identifier: string;
    amount: string;
    fees: string;
    receiver_name: string | null;
    sender_phone: string;
    sender_transfer_card_id: number | null;
    sender_transfer_card: CardType | null;
    card_number: string | null;
    receiver_phone: string;
    service_id: number;
    pay_in_channel_id: number;
    pay_out_channel_id: number;
    status: string;
    status_pay_in: string;
    status_pay_out: string;
    ref_pay_in_partner: string;
    ref_pay_out_partner: string;
    french_receipt_url: string;
    english_receipt_url: string;
    support_fees: number;
    deleted_at: string | null;
    created_at: string | null;
    updated_at: string | null;
    receipt_url: string | null;
    successPayInTransaction: SuccessPayTransaction | null;
    successPayOutTransaction: SuccessPayTransaction | null;
    pay_out_channel: PayChannel | null;
    pay_in_channel: PayChannel | null;
    cash_in_calls: CashCall[];
    cash_out_calls: CashCall[];
}

export interface CardType {
    id: string;
    name: string;
    type: string;
    account_id: number;
    country_id: number | null;
    updated_at: string | null;
    created_at: string | null;
    deleted_at: string | null;
    validated_at: string | null;
    activated_at: string | null;
    account: AccountType;
    country: CountryType;
    card_number: string;
}

export interface UserActivityType {
    id: string;
    log_name: string;
    description: string;
    subject_type: string;
    event: string;
    subject_id: string | null;
    causer_type: string;
    causer_id: number;
    properties: string;
    batch_uuid: string | null;
    created_at: string;
    updated_at: string;
    complet_event_description: string;
    causer: UserType;
    subject: string | null;
}
