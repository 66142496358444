import Loading from "@ui/Loading.tsx";
import { ButtonHTMLAttributes, ReactNode, useMemo } from "react";

import { cn } from "@/libs/style.ts";

const ICON_PLACEMENT = {
    LEFT: "left",
    RIGHT: "right"
};

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    loading?: boolean;
    size?: "sm" | "md";
    variant?: "default" | "outline" | "gray";
    disabled?: boolean;
    withAuto?: boolean;
    icon?: ReactNode;
    iconPlacement?: "left" | "right";
}

const Button = (props: Props) => {
    const {
        loading,
        size = "md",
        variant = "default",
        withAuto = false,
        disabled = false,
        className,
        icon,
        iconPlacement = ICON_PLACEMENT.LEFT,
        ...rest
    } = props;

    const iconPosition = useMemo(
        () => ({
            left: icon && iconPlacement === ICON_PLACEMENT.LEFT,
            right: icon && iconPlacement === ICON_PLACEMENT.RIGHT
        }),
        [icon, iconPlacement]
    );

    return (
        <button
            {...rest}
            disabled={disabled}
            className={cn({
                "flex items-center font-medium justify-center px-6 text-base rounded-md":
                    true,
                "w-full rounded-md space-x-3 disabled:cursor-not-allowed": true,
                "bg-blue-600 text-white": variant === "default",
                "border border-blue-600 text-blue-600": variant === "outline",
                "border text-gray-600": variant === "gray",
                "focus:outline-none focus:ring": true,
                "focus:border-blue-600 focus:ring-blue-600/20":
                    variant === "default" ||
                    variant === "outline" ||
                    variant === "gray",
                "cursor-not-allowed": disabled || loading,
                "bg-blue-700": (disabled || loading) && variant === "default",
                "bg-gray-400/10": (disabled || loading) && variant === "gray",
                "bg-blue-600/10":
                    (disabled || loading) && variant === "outline",
                "w-auto": withAuto,
                "py-2": size === "md",
                "text-sm py-1.5": size === "sm",
                [className ?? ""]: true
            })}
        >
            {loading && (
                <Loading
                    className={cn({
                        "-mr-2": true,
                        "-mr-1": iconPosition.left && !!icon
                    })}
                />
            )}
            {iconPosition.left && <span>{icon}</span>}
            <span>{props.children}</span>
            {iconPosition.right && <span>{icon}</span>}
        </button>
    );
};

export default Button;
