import BsCheckCircleOutlineIcon from "@icon/BsCheckCircleOutlineIcon.tsx";
import FaRegStopCircleIcon from "@icon/FaRegStopCircleIcon.tsx";
import IoReloadIcon from "@icon/IoReloadIcon.tsx";
import SiCloseIcon from "@icon/SiCloseIcon.tsx";
import TbDownloadIcon from "@icon/TbDownloadIcon.tsx";
import Button from "@ui/Button.tsx";
import ConfirmModal from "@ui/ConfirmModal.tsx";
import { useCallback, useEffect, useMemo, useState } from "react";

import ReceiptRow from "../../../components/ReceiptRow.tsx";
import {
    ENDPOINTS,
    KYC_TYPE,
    PERMISSIONS,
    RESPONSE_STATUS
} from "../../../constants";
import {
    formatDateToView,
    formatStatusAccount,
    formatStatusKyc,
    updateAxiosBaseUrl,
    userHavePermissions
} from "../../../helpers";
import useCountries from "../../../hooks/useCountries.tsx";
import useExportKyc from "../../../hooks/useExportKyc.ts";
import useManageWalletChannels from "../../../hooks/useManageWalletChannels.ts";
import useToast from "../../../libs/useToast.tsx";
import {
    AccountStatusType,
    ApiService,
    KycStatusType,
    KycType
} from "../../../types";

import KycPicture from "./KycPicture.tsx";
import ReasonSuspendModal from "./ReasonSuspendModal.tsx";
import ToReviewModal from "./ToReviewModal.tsx";

const USER_SUBMIT_REVIEW_KEY = "submit_review";

interface Props {
    kyc: KycType;
    onActionSuccess: (kyc: KycType) => void;
}

const Account = (props: Props) => {
    const { kyc, onActionSuccess } = props;
    const [showToReviewModal, setShowToReviewModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showReasonSuspend, setShowReasonSuspend] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [reason, setReason] = useState("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [confirmAction, setConfirmAction] = useState<
        KycStatusType | AccountStatusType | null
    >(null);
    const [comments, setComments] = useState({
        name: "",
        photo: "",
        selfie_photo: ""
    });
    const [kycSelected, setKycSelected] = useState({
        transfer: {
            value: false,
            disable: false
        },
        card: {
            value: false,
            disable: false
        }
    });

    const { customSuccess: customSuccessToast, customError: customErrorToast } =
        useToast();
    const { exportToPdf } = useExportKyc();
    const { getCountryByIndicative } = useCountries();
    const { payInCountriesAvailable } = useManageWalletChannels();

    const initiateAnAction = useCallback(
        (action: KycStatusType | AccountStatusType) => {
            return () => {
                if (KycStatusType.validated === action) {
                    setKycSelected({
                        transfer: {
                            value: !!kyc.transfer_kyc_validated_at,
                            disable: !!kyc.transfer_kyc_validated_at
                        },
                        card: {
                            value: !!kyc.card_kyc_validated_at,
                            disable: !!kyc.card_kyc_validated_at
                        }
                    });
                }

                if (KycStatusType.rejected === action) {
                    setKycSelected({
                        transfer: {
                            value: !!kyc.transfer_kyc_rejected_at,
                            disable: !!kyc.transfer_kyc_rejected_at
                        },
                        card: {
                            value: !!kyc.card_kyc_rejected_at,
                            disable: !!kyc.card_kyc_rejected_at
                        }
                    });
                }

                setShowConfirmModal(true);
                setConfirmAction(action);
            };
        },
        [kyc]
    );

    const closeConfirmModal = useCallback(() => setShowConfirmModal(false), []);

    const generateKycType = useCallback(() => {
        let kycType = "";

        if (kycSelected.transfer.value) {
            kycType = KYC_TYPE.TRANSFER;
        }

        if (kycSelected.card.value) {
            kycType = KYC_TYPE.CARD;
        }

        if (kycSelected.transfer.value && kycSelected.card.value) {
            kycType = KYC_TYPE.TRANSFER_CARD;
        }

        return kycType;
    }, [kycSelected.card.value, kycSelected.transfer.value]);

    const validateKyc = useCallback(() => {
        if (kyc.id) {
            const kycType = generateKycType();

            const kycTransferValidated =
                !!kyc.transfer_kyc_validated_at &&
                kycType === KYC_TYPE.TRANSFER;
            const kycCardValidated =
                !!kyc.card_kyc_validated_at && kycType === KYC_TYPE.CARD;

            if (!(kycTransferValidated || kycCardValidated)) {
                setConfirmLoading(true);
                updateAxiosBaseUrl(ApiService.moc);

                window.axios
                    .put(ENDPOINTS.VALIDATE_ACCOUNT.replace(":id", kyc.id), {
                        kyc_type: kycType
                    })
                    .then(response => {
                        onActionSuccess && onActionSuccess(response.data.data);
                        customSuccessToast({
                            message: "KYC valider avec succès"
                        });
                        closeConfirmModal();
                    })
                    .catch(() => {
                        customErrorToast({
                            message: "Echec de validation du KYC"
                        });
                    })
                    .finally(() => setConfirmLoading(false));
            } else {
                if (kycTransferValidated)
                    customErrorToast(
                        { message: "Le KYC transfert a été déjà validé" },
                        { duration: 8000 }
                    );

                if (kycCardValidated)
                    customErrorToast(
                        { message: "Le KYC carte a été déjà validé" },
                        { duration: 8000 }
                    );
            }
        }
    }, [
        closeConfirmModal,
        customErrorToast,
        customSuccessToast,
        generateKycType,
        kyc.card_kyc_validated_at,
        kyc.id,
        kyc.transfer_kyc_validated_at,
        onActionSuccess
    ]);

    const rejectKyc = useCallback(() => {
        if (kyc.id) {
            setConfirmLoading(true);
            updateAxiosBaseUrl(ApiService.moc);

            window.axios
                .put(ENDPOINTS.REJECT_ACCOUNT.replace(":id", kyc.id), {
                    reason
                })
                .then(response => {
                    customSuccessToast({
                        message: "KYC rejeter avec succès"
                    });
                    closeConfirmModal();
                    onActionSuccess && onActionSuccess(response.data.data);
                    setReason("");
                })
                .catch(errors => {
                    if (
                        errors.response.status ===
                        RESPONSE_STATUS.UNPROCESSABLE_ENTITY
                    ) {
                        setErrors(errors.response.data.errors);
                    }
                    customErrorToast({ message: "Echec du rejet du KYC" });
                })
                .finally(() => {
                    setConfirmLoading(false);
                });
        }
    }, [
        closeConfirmModal,
        customErrorToast,
        customSuccessToast,
        kyc.id,
        onActionSuccess,
        reason
    ]);

    const activeOrDisableKyc = useCallback(
        (active = true) => {
            if (kyc.id) {
                setConfirmLoading(true);
                updateAxiosBaseUrl(ApiService.moc);
                const endpoint = active
                    ? ENDPOINTS.ACTIVE_ACCOUNT.replace(":id", kyc.id)
                    : ENDPOINTS.SUSPEND_ACCOUNT.replace(":id", kyc.id);
                window.axios
                    .put(endpoint, {
                        reason
                    })
                    .then(response => {
                        Object.keys(errors).length && setErrors({});
                        customSuccessToast({
                            message: `KYC ${
                                active ? "activé" : "suspendu"
                            } avec succès`
                        });
                        setShowReasonSuspend(false);
                        onActionSuccess && onActionSuccess(response.data.data);
                        closeConfirmModal();
                    })
                    .catch(errorResponse => {
                        Object.keys(errors).length && setErrors({});
                        const errorsInResponse =
                            errorResponse.response.data?.errors || {};
                        customErrorToast({
                            message: `Echec de ${
                                active ? "réactivation" : "suspension"
                            } du KYC`
                        });
                        const status = errorResponse.response.status;

                        if (status === RESPONSE_STATUS.UNPROCESSABLE_ENTITY) {
                            setErrors(errorsInResponse);
                        }
                    })
                    .finally(() => setConfirmLoading(false));
            }
        },
        [
            closeConfirmModal,
            customErrorToast,
            customSuccessToast,
            errors,
            kyc.id,
            onActionSuccess,
            reason
        ]
    );

    const suspendKyc = useCallback(() => {
        activeOrDisableKyc(false);
    }, [activeOrDisableKyc]);

    const toReviewKyc = useCallback(() => {
        if (kyc.id) {
            updateAxiosBaseUrl(ApiService.moc);
            setConfirmLoading(true);
            let kycType = KYC_TYPE.TRANSFER;

            if (comments.name || comments.photo) {
                kycType = KYC_TYPE.TRANSFER;
            }

            if (comments.selfie_photo && !comments.name && !comments.photo) {
                kycType = KYC_TYPE.CARD;
            }

            if (comments.selfie_photo && (comments.name || comments.photo)) {
                kycType = KYC_TYPE.TRANSFER_CARD;
            }

            window.axios
                .put(ENDPOINTS.REVIEW_ACCOUNT.replace(":id", kyc.id), {
                    photo: comments.photo,
                    selfie_photo: comments.selfie_photo,
                    full_name: comments.name,
                    kyc_type: kycType
                })
                .then(response => {
                    Object.keys(errors).length && setErrors({});
                    const data = response.data.data;

                    if (Object.keys(data).length > 0 && onActionSuccess) {
                        onActionSuccess(data);
                    }

                    customSuccessToast({ message: "Success du à revoir" });
                    setShowToReviewModal(false);
                })
                .catch(error => {
                    Object.keys(errors).length && setErrors({});
                    customErrorToast({ message: "Echec du à revoir" });
                    const status = error.response.status;
                    const errorInResponse = error.response.data.errors || {};
                    if (status === RESPONSE_STATUS) {
                        setErrors(errorInResponse);
                    }
                })
                .finally(() => setConfirmLoading(false));
        }
    }, [
        comments.name,
        comments.photo,
        comments.selfie_photo,
        customErrorToast,
        customSuccessToast,
        errors,
        kyc.id,
        onActionSuccess
    ]);

    const reactiveKyc = useCallback(() => {
        activeOrDisableKyc();
    }, [activeOrDisableKyc]);

    const confirmModalData = useMemo(() => {
        switch (confirmAction) {
            case KycStatusType.validated:
                return {
                    title: "Confirmer la validation",
                    description: "Etes-vous sûr de vouloir valider le KYC?",
                    onConfirm: validateKyc
                };
            case KycStatusType.rejected:
                return {
                    title: "Confirmer le rejet",
                    description: "Etes-vous sûr de vouloir rejeter le KYC?",
                    onConfirm: rejectKyc
                };
            case AccountStatusType.suspended:
                return {
                    title: "Confirmer la suspension",
                    description: "Etes-vous sûr de vouloir suspendre le KYC?",
                    onConfirm: () => {
                        setShowConfirmModal(false);
                        setShowReasonSuspend(true);
                    }
                };
            case AccountStatusType.actif:
                return {
                    title: "Confirmer la réactivation",
                    description: "Etes-vous sûr de vouloir réactiver le KYC?",
                    onConfirm: reactiveKyc
                };
            default:
                return {
                    title: "",
                    description: "",
                    onConfirm: undefined
                };
        }
    }, [confirmAction, reactiveKyc, rejectKyc, validateKyc]);

    const countryInKyc = useMemo(() => {
        if (kyc.user.phone_with_indicative) {
            const numberSplit = kyc.user.phone_with_indicative.split(" ");
            if (numberSplit.length === 1) return "Autre pays";
            const indicative = numberSplit[0];

            if (indicative && payInCountriesAvailable.includes(indicative)) {
                return getCountryByIndicative(indicative)?.name ?? "---";
            } else {
                return "Autre pays";
            }
        }

        return "---";
    }, [
        getCountryByIndicative,
        kyc.user.phone_with_indicative,
        payInCountriesAvailable
    ]);

    const suspendOrActiveData = useMemo(() => {
        if (kyc.status === AccountStatusType.suspended)
            return { text: "Réactiver", status: AccountStatusType.actif };

        return { text: "Suspendre", status: AccountStatusType.suspended };
    }, [kyc.status]);

    const commentData = useMemo(() => {
        const defaultValue = {
            full_name: {
                comment: "",
                value: ""
            },
            photo: {
                comment: "",
                value: ""
            },
            selfie_photo: { comment: "", value: "" },
            haveOldFullName: false,
            haveOldPhoto: false,
            haveOldSelfiePhoto: false
        };

        if (!kyc.comments) return defaultValue;

        const parseData = JSON.parse(kyc.comments);

        if (Object.keys(parseData).length === 0) {
            return defaultValue;
        }

        const haveOldFullName =
            !!parseData?.full_name && !!parseData?.full_name?.value;
        const haveFullNameComment =
            !!parseData?.full_name && !!parseData?.full_name?.comment;

        const haveOldPhoto = !!parseData?.photo && !!parseData?.photo?.value;
        const havePhotoComment =
            !!parseData?.photo && !!parseData?.photo?.comment;

        const haveOldSelfiePhoto =
            !!parseData?.selfie_photo && !!parseData?.selfie_photo?.value;
        const haveSelfieComment =
            !!parseData?.selfie_photo && !!parseData?.selfie_photo?.comment;

        return {
            full_name: {
                comment: haveFullNameComment
                    ? parseData?.full_name?.comment
                    : "",
                value: haveOldFullName ? parseData?.full_name?.value : ""
            },
            photo: {
                comment: havePhotoComment ? parseData?.photo?.comment : "",
                value: haveOldPhoto ? parseData?.photo?.value : ""
            },
            selfie_photo: {
                comment: haveSelfieComment
                    ? parseData?.selfie_photo.comment
                    : "",
                value: haveOldSelfiePhoto ? parseData?.selfie_photo.value : ""
            },
            haveOldFullName: haveOldFullName,
            haveOldPhoto: haveOldPhoto,
            haveOldSelfiePhoto: haveOldSelfiePhoto
        };
    }, [kyc]);

    useEffect(() => {
        if (!kyc.comments) {
            setComments({
                name: "",
                photo: "",
                selfie_photo: ""
            });
        } else {
            setComments({
                name: commentData?.full_name?.comment || "",
                photo: commentData?.photo?.comment || "",
                selfie_photo: commentData?.selfie_photo?.comment || ""
            });
        }
    }, [
        commentData?.full_name?.comment,
        commentData?.photo?.comment,
        commentData?.selfie_photo?.comment,
        kyc,
        kyc.comments
    ]);

    const canRejected = useMemo(() => {
        const transferCheck =
            !kyc.transfer_kyc_rejected_at && !kyc.transfer_kyc_validated_at;

        const cardCheck =
            !kyc.card_kyc_rejected_at && !kyc.card_kyc_validated_at;
        return (
            (transferCheck || cardCheck) &&
            userHavePermissions([PERMISSIONS.REJECT_KYC])
        );
    }, [
        kyc.card_kyc_rejected_at,
        kyc.card_kyc_validated_at,
        kyc.transfer_kyc_rejected_at,
        kyc.transfer_kyc_validated_at
    ]);

    const canReview = useMemo(() => {
        return (
            userHavePermissions([PERMISSIONS.REVIEW_KYC]) &&
            !kyc.transfer_kyc_rejected_at
        );
    }, [kyc.transfer_kyc_rejected_at]);

    const rejectedReason = useMemo(() => {
        if (kyc.transfer_kyc_rejected_reason)
            return kyc.transfer_kyc_rejected_reason;

        if (kyc.card_kyc_rejected_reason) return kyc.card_kyc_rejected_reason;

        return "---";
    }, [kyc.card_kyc_rejected_reason, kyc.transfer_kyc_rejected_reason]);

    return (
        <>
            {showToReviewModal && (
                <ToReviewModal
                    closeModal={() => setShowToReviewModal(false)}
                    isOpen={showToReviewModal}
                    comments={comments}
                    onCommentChange={value => setComments(value)}
                    onSubmit={toReviewKyc}
                    loading={confirmLoading}
                    errors={errors}
                    kyc={kyc}
                    commentData={commentData}
                />
            )}

            <>
                <div className="flex items-center p-6 gap-5">
                    <div className="flex-1 grid grid-cols-2 gap-10 text-sm text-gray-500">
                        <div className="relative">
                            {commentData.haveOldFullName &&
                                kyc.transfer_kyc_status ===
                                    USER_SUBMIT_REVIEW_KEY && (
                                    <div className="absolute w-full -top-6 flex items-center justify-end">
                                        <div className="w-2/3 flex items-center justify-between">
                                            <div className="truncate">
                                                Ancienne valeur
                                            </div>
                                            <div className="truncate">
                                                Nouvelle valeur
                                            </div>
                                        </div>
                                    </div>
                                )}

                            <div className="flex items-center justify-between">
                                <div>Nom</div>

                                {commentData.haveOldFullName &&
                                kyc.transfer_kyc_status ===
                                    USER_SUBMIT_REVIEW_KEY ? (
                                    <div className="w-2/3 flex items-center justify-between">
                                        <div>{kyc.user.full_name}</div>
                                        <div>{commentData.full_name.value}</div>
                                    </div>
                                ) : (
                                    <div>{kyc.user.full_name}</div>
                                )}
                            </div>

                            <ReceiptRow
                                label="Statut compte"
                                value={formatStatusAccount(kyc.status)}
                            />

                            <ReceiptRow
                                label="Email"
                                value={kyc.user.email ?? "---"}
                            />

                            <hr className="my-3" />

                            <ReceiptRow
                                label="Date de création du compte"
                                value={formatDateToView(kyc.created_at)}
                            />

                            <ReceiptRow
                                label="Statut du KYC transfert"
                                value={formatStatusKyc(
                                    kyc.transfer_kyc_status ?? ""
                                )}
                            />

                            <ReceiptRow
                                label="Statut du KYC carte"
                                value={formatStatusKyc(
                                    kyc.card_kyc_status ?? ""
                                )}
                            />

                            <ReceiptRow
                                label="Date de validation KYC transfert"
                                value={formatDateToView(
                                    kyc.transfer_kyc_validated_at
                                )}
                            />

                            <ReceiptRow
                                label="Date de validation KYC carte"
                                value={formatDateToView(
                                    kyc.card_kyc_validated_at
                                )}
                            />

                            <ReceiptRow
                                label="Valider par"
                                value={kyc.validated_by?.full_name ?? "---"}
                            />

                            <ReceiptRow
                                label="Date de suspenssion"
                                value={formatDateToView(kyc.suspended_at)}
                            />

                            <ReceiptRow
                                label="Motif de suspenssion"
                                value={kyc?.suspension_reason ?? "---"}
                            />

                            <ReceiptRow
                                label="Date de rejet KYC transfert"
                                value={formatDateToView(
                                    kyc.transfer_kyc_rejected_at
                                )}
                            />

                            <ReceiptRow
                                label="Date de rejet KYC carte"
                                value={formatDateToView(
                                    kyc.card_kyc_rejected_at
                                )}
                            />

                            <br />

                            <hr className="my-3" />
                        </div>

                        <div>
                            <ReceiptRow
                                label="Téléphone"
                                value={kyc.user.phone_with_indicative || "---"}
                            />

                            <ReceiptRow
                                label="ID Compte"
                                value={kyc.account_number || "---"}
                            />

                            <br />

                            <hr className="my-3" />

                            <ReceiptRow
                                label="Date à revoir KYC transfert"
                                value={formatDateToView(
                                    kyc.transfer_kyc_to_review_at
                                )}
                            />

                            <ReceiptRow
                                label="Date à revoir KYC carte"
                                value={formatDateToView(
                                    kyc.card_kyc_to_review_at
                                )}
                            />

                            <ReceiptRow
                                label="Date de suppression du compte"
                                value={formatDateToView(kyc.deleted_at)}
                            />

                            <ReceiptRow label="Pays" value={countryInKyc} />

                            <ReceiptRow
                                label="Dernière modification"
                                value={formatDateToView(kyc.updated_at)}
                            />

                            <hr className="my-3" />

                            <ReceiptRow
                                label="Motif de rejet du KYC du compte"
                                value={rejectedReason}
                            />
                            <br />
                            <br />

                            <hr className="mb-3 mt-12" />
                        </div>
                    </div>

                    <div className="space-y-5">
                        <div className="flex items-center justify-between space-x-3">
                            <KycPicture
                                src={kyc.user.photo ?? ""}
                                valueKey="kyc-user-photo"
                                type={
                                    commentData.haveOldPhoto &&
                                    kyc.transfer_kyc_status ===
                                        USER_SUBMIT_REVIEW_KEY
                                        ? "old"
                                        : undefined
                                }
                            />

                            {commentData.haveOldPhoto &&
                                kyc.transfer_kyc_status ===
                                    USER_SUBMIT_REVIEW_KEY && (
                                    <KycPicture
                                        type="new"
                                        src={commentData.photo.value}
                                        valueKey="commentData-photo-value"
                                    />
                                )}
                        </div>

                        <div className="flex items-center justify-between space-x-3">
                            <KycPicture
                                label="Selfie avec pièce d'identité"
                                src={kyc.user.selfie_photo ?? ""}
                                valueKey="kyc-user-selfie-photo"
                                type={
                                    commentData.haveOldSelfiePhoto &&
                                    kyc.card_kyc_status ===
                                        USER_SUBMIT_REVIEW_KEY
                                        ? "old"
                                        : undefined
                                }
                            />

                            {commentData.haveOldSelfiePhoto &&
                                kyc.card_kyc_status ===
                                    USER_SUBMIT_REVIEW_KEY && (
                                    <KycPicture
                                        label="Selfie avec pièce d'identité"
                                        type="new"
                                        src={commentData.selfie_photo.value}
                                        valueKey="commentData-selfie_photo-value"
                                    />
                                )}
                        </div>
                    </div>
                </div>

                {!!kyc.user.phone_with_indicative &&
                    kyc.status !== "deleted" && (
                        <div className="mt-4 p-6 border-t flex items-center justify-end space-x-4">
                            {userHavePermissions([PERMISSIONS.VALIDATE_KYC]) &&
                                (kyc.transfer_kyc_validated_at === null ||
                                    (kyc.transfer_kyc_validated_at &&
                                        kyc.transfer_kyc_status ===
                                            USER_SUBMIT_REVIEW_KEY) ||
                                    kyc.card_kyc_validated_at === null ||
                                    (kyc.card_kyc_validated_at &&
                                        kyc.card_kyc_status ===
                                            USER_SUBMIT_REVIEW_KEY)) && (
                                    <Button
                                        onClick={initiateAnAction(
                                            KycStatusType.validated
                                        )}
                                        variant="gray"
                                        withAuto
                                        className="px-2"
                                        icon={<BsCheckCircleOutlineIcon />}
                                    >
                                        Valider KYC
                                    </Button>
                                )}

                            {canReview && (
                                <Button
                                    onClick={() => {
                                        Object.keys(errors).length &&
                                            setErrors({});
                                        setShowToReviewModal(true);
                                    }}
                                    variant="gray"
                                    withAuto
                                    className="px-2"
                                    icon={<IoReloadIcon />}
                                >
                                    A revoir
                                </Button>
                            )}

                            {canRejected && (
                                <Button
                                    onClick={initiateAnAction(
                                        KycStatusType.rejected
                                    )}
                                    variant="gray"
                                    withAuto
                                    className="px-2"
                                    icon={<SiCloseIcon />}
                                >
                                    Rejeter
                                </Button>
                            )}

                            {userHavePermissions([PERMISSIONS.SUSPEND_KYC]) && (
                                <Button
                                    onClick={initiateAnAction(
                                        suspendOrActiveData.status
                                    )}
                                    variant="gray"
                                    withAuto
                                    className="px-2"
                                    icon={<FaRegStopCircleIcon />}
                                >
                                    {suspendOrActiveData.text}
                                </Button>
                            )}

                            <Button
                                onClick={() => kyc && exportToPdf(kyc)}
                                withAuto
                                className="px-2"
                                icon={<TbDownloadIcon />}
                            >
                                Télécharger le KYC
                            </Button>
                        </div>
                    )}
            </>

            {showConfirmModal && (
                <ConfirmModal
                    isOpen={showConfirmModal}
                    closeModal={() => setShowConfirmModal(false)}
                    title={confirmModalData.title}
                    description={confirmModalData.description}
                    loading={confirmLoading}
                    onConfirm={confirmModalData.onConfirm}
                    kyc={kyc}
                    kycSelected={kycSelected}
                    setKycSelected={setKycSelected}
                    confirmAction={confirmAction}
                    reason={reason}
                    setReason={setReason}
                    error={errors?.reason}
                />
            )}

            {showReasonSuspend && (
                <ReasonSuspendModal
                    isOpen={showReasonSuspend}
                    closeModal={() => {
                        setShowReasonSuspend(false);
                        setShowConfirmModal(true);
                    }}
                    loading={confirmLoading}
                    onConfirm={suspendKyc}
                    reason={reason}
                    onReasonChange={e => setReason(e.target.value)}
                    error={errors?.reason}
                />
            )}
        </>
    );
};

export default Account;
