import { Menu } from "@headlessui/react";
import GsFormCloseIcon from "@icon/GsFormCloseIcon.tsx";
import HiChevronDownIcon from "@icon/HiChevronDownIcon.tsx";
import Checkbox from "@ui/Checkbox.tsx";
import { Fragment, useCallback } from "react";

import { DropdownTransition } from "@/components/transitions";
import { cn } from "@/libs/style.ts";

interface OptionType {
    value: string;
    label: string;
}

interface Props {
    options: OptionType[];
    value: OptionType[];
    onValueChange: (value: OptionType[]) => void;
    containerClassName?: string;
    placeholder?: string;
    error?: boolean;
}

const ValidatedBySelect = (props: Props) => {
    const {
        options,
        value,
        onValueChange,
        containerClassName = "",
        placeholder = "",
        error = false
    } = props;

    const handleSelectItem = useCallback(
        (optItem: OptionType) => {
            const findItem = value.find(item => item.value === optItem.value);

            if (findItem) {
                onValueChange(
                    value.filter(item => item.value !== optItem.value)
                );
            } else {
                onValueChange([...value, optItem]);
            }
        },
        [onValueChange, value]
    );

    const itemSelected = useCallback(
        (item: OptionType) => {
            return !!value.find(valueItem => valueItem.value === item.value);
        },
        [value]
    );

    return (
        <Menu as="div" className={cn("relative", containerClassName)}>
            {({ open }) => (
                <>
                    <Menu.Button
                        className={cn({
                            "w-full h-[42px] py-2.5 pl-4 pr-16": true,
                            "flex items-center text-sm": true,
                            "rounded-md bg-white mt-2": true,
                            "border border-gray-300": true,
                            "border-blue-600 ring ring-blue-600/20": open,
                            "border-red-500 ring ring-red-500/20": error
                        })}
                        data-testid="select-button"
                    >
                        <p
                            className={cn({
                                truncate: true
                            })}
                        >
                            {placeholder || ""}{" "}
                            {value.map((item, index) => (
                                <Fragment key={index}>
                                    {item.label}
                                    {index + 1 < value.length && ", "}
                                </Fragment>
                            ))}
                        </p>
                    </Menu.Button>

                    <div className="flex items-center space-x-2 absolute top-3 right-2">
                        {value.length > 0 && (
                            <button
                                data-testid="select-clear"
                                className="focus:outline-none"
                                onClick={() => onValueChange([])}
                            >
                                <GsFormCloseIcon className="text-gray-400 hover:text-gray-600" />
                            </button>
                        )}

                        <span className="text-lg pb-0.5 w-[1px] h-5 border-l" />
                        <HiChevronDownIcon
                            className={cn({
                                "transition duration-200 text-gray-400 hover:text-gray-600 -rotate-90":
                                    true,
                                "rotate-0": open
                            })}
                        />
                    </div>

                    <DropdownTransition>
                        <Menu.Items
                            data-testid="select-menu"
                            as="div"
                            className="border bg-white z-10 shadow absolute w-full mt-1 rounded-md px-2 py-1"
                            onClick={e => e.preventDefault()}
                        >
                            <ul className="max-h-[240px] overflow-y-scroll">
                                {options.map((optItem, index) => (
                                    <li
                                        key={index}
                                        onClick={() =>
                                            handleSelectItem(optItem)
                                        }
                                        data-testid={`select-item-${index}`}
                                        className={cn(
                                            "flex items-center -space-x-4",
                                            "py-2 px-4 text-sm text-gray-700",
                                            "hover:text-blue-600 hover:bg-blue-100",
                                            "rounded-md list-none cursor-pointer"
                                        )}
                                    >
                                        <Checkbox
                                            checked={itemSelected(optItem)}
                                            onChange={() => {}}
                                        />
                                        <span className="pl-8">
                                            {optItem?.label}
                                        </span>
                                    </li>
                                ))}

                                {options.length === 0 && (
                                    <li
                                        className={cn(
                                            "flex items-center -space-x-4",
                                            "py-2 px-4 text-sm text-gray-700",
                                            "rounded-md list-none cursor-pointer"
                                        )}
                                    >
                                        Liste vide
                                    </li>
                                )}
                            </ul>
                        </Menu.Items>
                    </DropdownTransition>
                </>
            )}
        </Menu>
    );
};

export default ValidatedBySelect;
