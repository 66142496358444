import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import config from "../config";
import { ENDPOINTS } from "../constants";
import useToast from "../libs/useToast.tsx";

import useAuth from "./useAuth.ts";

import { clearActivityTimer, updateAxiosBaseUrl } from "@/helpers";
import { RootState } from "@/store";
import { logout, setLogoutLoading } from "@/store/authSlice.ts";
import { ApiService } from "@/types";

const useLogout = () => {
    const { logoutLoading } = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();
    const { customSuccess, customError } = useToast();
    const navigate = useNavigate();
    const { isLogin } = useAuth();

    const clientLogout = useCallback(
        (onSuccess?: () => void) => {
            dispatch(logout());
            clearActivityTimer(true);
            navigate(config.auth.redirectLogoutUrl);
            onSuccess && onSuccess();
        },
        [dispatch, navigate]
    );

    return {
        logout: (logoutMessage?: string, onSuccess?: () => void) => {
            if (!isLogin) {
                clientLogout(onSuccess);
                logoutMessage && customSuccess({ message: logoutMessage });
            } else {
                dispatch(setLogoutLoading(true));
                updateAxiosBaseUrl(ApiService.auth);
                window.axios
                    .post(ENDPOINTS.LOGOUT)
                    .then(() => {
                        logoutMessage &&
                            customSuccess({ message: logoutMessage });
                        clientLogout(onSuccess);
                    })
                    .catch(() => {
                        customError({ message: "Échec de la déconnexion" });
                    })
                    .finally(() => dispatch(setLogoutLoading(false)));
            }
        },
        loading: logoutLoading,
        sessionIsExpire: isLogin
    };
};

export default useLogout;
