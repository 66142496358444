import { createColumnHelper } from "@tanstack/table-core";
import Button from "@ui/Button.tsx";
import Checkbox from "@ui/Checkbox.tsx";
import Datatable from "@ui/datatable";
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";

import { DATATABLE, ENDPOINTS, SECOND_DATE_TIME_FR_FORMAT } from "@/constants";
import { downloadFile, updateAxiosBaseUrl } from "@/helpers";
import useCheckboxDatatable from "@/hooks/useCheckboxDatatable.ts";
import { formatDate } from "@/libs/date.ts";
import useToast from "@/libs/useToast.tsx";
import LogsFilters from "@/pages/admin/components/LogsFilters.tsx";
import { ApiService, UserActivityType } from "@/types";

interface DataType {
    currentPageData: UserActivityType[];
    total: number;
}

interface FilterType {
    email?: string;
    startDate?: string;
    endDate?: string;
    limit?: number;
    page?: number;
}

const columnHelper = createColumnHelper<UserActivityType>();

const Logs = () => {
    const [data, setData] = useState<DataType>({
        currentPageData: [],
        total: 0
    });
    const [{ pageIndex, pageSize }, setPaginate] = useState({
        pageIndex: DATATABLE.PAGE_INDEX,
        pageSize: DATATABLE.PAGE_SIZE
    });
    const [loading, setLoading] = useState(false);
    const [filterInProgress, setFilterInProgress] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [filter, setFilter] = useState<FilterType>({
        email: "",
        startDate: "",
        endDate: ""
    });
    const [submitFilter, setSubmitFilter] = useState<FilterType>({
        email: "",
        startDate: "",
        endDate: ""
    });

    const {
        checkData,
        checkAll,
        handleCheckAll,
        handleCheckItem,
        printItemCheckboxStatus,
        resetCheckData
    } = useCheckboxDatatable({ totalData: data.total });
    const { customSuccess: customSuccessToast, customError: customErrorToast } =
        useToast();

    const fetchData = useCallback((params: FilterType) => {
        const sendData = {
            causer_email: params.email,
            created_at: {
                start_at: params.startDate,
                end_at: params.endDate
            },
            limit: params.limit,
            page: params.page
        };

        if (params.email || params.startDate || params.endDate) {
            setFilterInProgress(true);
        } else {
            setLoading(true);
        }

        updateAxiosBaseUrl(ApiService.moc);
        window.axios
            .get(ENDPOINTS.ACTIVITY_LOGS, { params: sendData })
            .then(response => {
                const { data } = response.data;
                setData({
                    currentPageData: data.activities,
                    total: data.total
                });
            })
            .catch(() =>
                setData({
                    total: 0,
                    currentPageData: []
                })
            )
            .finally(() => {
                if (params.email || params.startDate || params.endDate) {
                    setFilterInProgress(false);
                } else {
                    setLoading(false);
                }
            });
    }, []);

    useEffect(() => {
        fetchData({
            email: submitFilter.email,
            startDate: submitFilter.startDate,
            endDate: submitFilter.endDate,
            page: pageIndex,
            limit: pageSize
        });
    }, [
        fetchData,
        pageIndex,
        pageSize,
        submitFilter.email,
        submitFilter.endDate,
        submitFilter.startDate
    ]);

    const handleFilter = useCallback(
        (e: FormEvent) => {
            e.preventDefault();
            setPaginate({
                pageIndex: DATATABLE.PAGE_INDEX,
                pageSize: DATATABLE.PAGE_SIZE
            });
            resetCheckData();
            setSubmitFilter(filter);
        },
        [filter, resetCheckData]
    );

    const handleDownloadActivityLog = useCallback(() => {
        const sendData = {
            ...checkData,
            filter: {
                causer_email: submitFilter.email,
                created_at: {
                    start_at: submitFilter.startDate,
                    end_at: submitFilter.endDate
                }
            }
        };

        setDownloading(true);
        updateAxiosBaseUrl(ApiService.moc);
        const downloadSheetPromise = window.axios.get(
            ENDPOINTS.DOWNLOAD_ACTIVITY_LOG,
            { params: { ...sendData, output_file_type: "sheet" } }
        );
        /*const downloadPdfPromise = window.axios.get(
            ENDPOINTS.DOWNLOAD_ACTIVITY_LOG,
            { params: { ...sendData, output_file_type: "pdf" } }
        );*/
        Promise.all([downloadSheetPromise])
            .then(values => {
                const [{ data: dataSheet }] = values;
                const { data: endDataSheet, message: messageSheet } = dataSheet;
                const sheetUrl = endDataSheet.url;

                if (sheetUrl && typeof sheetUrl === "string") {
                    downloadFile(sheetUrl);
                    setTimeout(() => {
                        customSuccessToast({
                            message: "Succès du téléchargement"
                        });
                    }, 1000);
                } else {
                    customSuccessToast({
                        message: messageSheet || ""
                    });
                }
            })
            .catch(() => {
                customErrorToast({ message: "Echec du téléchargement" });
            })
            .finally(() => {
                setDownloading(false);
            });
    }, [
        checkData,
        customErrorToast,
        customSuccessToast,
        submitFilter.email,
        submitFilter.endDate,
        submitFilter.startDate
    ]);

    const columns = useMemo(
        () => [
            columnHelper.accessor(row => row.created_at, {
                id: "created_at",
                header: () => (
                    <div className="flex items-center space-x-3">
                        <Checkbox
                            id="check-all"
                            checked={checkAll}
                            onChange={handleCheckAll}
                        />

                        <span>Date et heure</span>
                    </div>
                ),
                cell: info => (
                    <div className="flex items-center space-x-3">
                        <Checkbox
                            checked={printItemCheckboxStatus(
                                info.row.original.id
                            )}
                            onChange={e =>
                                handleCheckItem(e, info.row.original.id)
                            }
                        />

                        <span>
                            {info.getValue()
                                ? formatDate(
                                      info.getValue() ?? "",
                                      SECOND_DATE_TIME_FR_FORMAT.replaceAll(
                                          "-",
                                          " "
                                      )
                                  )
                                : "---"}
                        </span>
                    </div>
                ),
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.id, {
                id: "email",
                header: () => "Email ou Téléphone",
                cell: info =>
                    info.row.original?.causer?.email ||
                    info.row.original?.causer?.phone_with_indicative ||
                    "---",
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.complet_event_description, {
                id: "description",
                header: () => "Action effectuée",
                cell: info => info.getValue(),
                footer: info => info.column.id
            })
        ],
        [checkAll, handleCheckAll, handleCheckItem, printItemCheckboxStatus]
    );

    return (
        <>
            <LogsFilters
                handleSubmit={handleFilter}
                loading={filterInProgress}
                params={filter}
                setParams={setFilter}
            />

            <div className="bg-white border p-3 rounded-md mb-5">
                <div className="flex items-center space-x-6 mb-7">
                    <h3 className="font-bold text-lg">
                        Liste des activités des utilisateurs
                    </h3>

                    <Button
                        className="px-3"
                        withAuto
                        loading={downloading}
                        disabled={
                            !checkData.uncheck.length &&
                            !checkData.check.length &&
                            !checkAll
                        }
                        onClick={handleDownloadActivityLog}
                    >
                        Télécharger
                    </Button>
                </div>

                <Datatable
                    columns={columns}
                    data={data.currentPageData}
                    allDataTotal={data.total}
                    manualFetchData
                    manualPaginateState={{
                        value: { pageIndex, pageSize },
                        setValue: setPaginate
                    }}
                    loading={loading || filterInProgress}
                    isSearchable={false}
                />
            </div>
        </>
    );
};

export default Logs;
