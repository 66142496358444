import { useModal } from "@ui/modal";
import { useCallback, useEffect, useMemo } from "react";
import { useIdleTimer } from "react-idle-timer";

import config from "@/config";
import { auth } from "@/helpers";
import useAuth from "@/hooks/useAuth.ts";
import useLogout from "@/hooks/useLogout.ts";

const useAutomaticDisconnect = () => {
    const { isOpen, closeModal, openModal } = useModal();
    const { logout: logoutUser } = useLogout();
    const { user } = useAuth();

    const timeout = useMemo(() => {
        const sessionExpire = parseInt(config.auth.inactivityTime);

        return sessionExpire * 60 * 1000;
    }, []);

    const handleOnIdle = useCallback(() => {
        logoutUser(undefined, openModal);
    }, [logoutUser, openModal]);

    useIdleTimer({
        timeout: timeout,
        onIdle: handleOnIdle,
        debounce: 500,
        disabled: !auth()?.isLogin
    });

    useEffect(() => {
        if (user) {
            const interval = setInterval(() => {
                if (!auth()?.isLogin) {
                    handleOnIdle();
                }
            }, 1000 * 60);

            return () => clearInterval(interval);
        }
    }, [handleOnIdle, logoutUser, openModal, user]);

    return {
        isOpen,
        closeModal
    };
};

export default useAutomaticDisconnect;
