import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { STORAGE } from "@/constants";
import { auth, storage } from "@/helpers";
import { TokenDataType, UserType } from "@/types";

export interface AuthState {
    isLogin: boolean;
    tokenData: TokenDataType | null;
    user: UserType | null;
    logoutLoading: boolean;
}

const authData = auth();

const initialState: AuthState = authData
    ? { ...authData, logoutLoading: false }
    : {
          isLogin: false,
          user: null,
          tokenData: null,
          logoutLoading: false
      };

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (
            state,
            action: PayloadAction<{ user: UserType; tokenData: TokenDataType }>
        ) => {
            state.isLogin = true;
            state.user = action.payload.user;
            state.tokenData = action.payload.tokenData;
            state.isLogin = true;
            storage().setItem(STORAGE.AUTH, JSON.stringify(state));
        },
        logout: state => {
            storage().removeItem(STORAGE.AUTH);
            storage().removeItem(STORAGE.CLIENT_CONFIG);
            state.isLogin = false;
            state.user = null;
            state.tokenData = null;
        },
        setLogoutLoading: (state, action: PayloadAction<boolean>) => {
            state.logoutLoading = action.payload;
        },
        setStoreVerifyOtp: (state, action: PayloadAction<boolean>) => {
            if (state.user) {
                state.user.confirmOtp = action.payload;
                storage().setItem(STORAGE.AUTH, JSON.stringify(state));
            }
        }
    }
});

export const { login, logout, setLogoutLoading, setStoreVerifyOtp } =
    authSlice.actions;

const authReducer = authSlice.reducer;

export default authReducer;
