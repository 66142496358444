import Button from "@ui/Button.tsx";
import Input from "@ui/Input.tsx";
import { ChangeEvent, FormEvent, useCallback, useMemo } from "react";

import { INPUT_DATE_FORMAT } from "@/constants";
import { addMonth, formatDate, subtractMonth } from "@/libs/date.ts";

interface ParamsType {
    email?: string;
    startDate?: string;
    endDate?: string;
}

interface Props {
    loading?: boolean;
    params: ParamsType;
    setParams: (value: ParamsType) => void;
    handleSubmit: (e: FormEvent) => void;
}

const LogsFilters = (props: Props) => {
    const { handleSubmit, loading = false, params, setParams } = props;

    const handleValueChange = useCallback(
        (key: keyof ParamsType) => {
            return (e: ChangeEvent<HTMLInputElement>) => {
                setParams({ ...params, [key]: e.target.value });
            };
        },
        [params, setParams]
    );

    const dateMetadata = useMemo(() => {
        let min = undefined;
        let max = undefined;

        if (params.startDate) {
            max = formatDate(addMonth(params.startDate, 3), INPUT_DATE_FORMAT);
        }

        if (params.endDate) {
            min = formatDate(
                subtractMonth(params.endDate, 3),
                INPUT_DATE_FORMAT
            );
        }

        return {
            min,
            max
        };
    }, [params.endDate, params.startDate]);

    return (
        <form
            onSubmit={handleSubmit}
            className="bg-white border p-3 rounded-md mb-5"
        >
            <h3 className="font-bold text-lg">Filtres</h3>

            <div className="flex items-end space-x-5">
                <div className="relative w-6/12">
                    <label htmlFor="email" className="text-[#6B7280] text-xs">
                        Email de l{"'"}utilisateurs
                    </label>

                    <Input
                        id="email"
                        type="email"
                        placeholder="Rechercher un utilisateur à partir de l’adresse email"
                        value={params.email}
                        onChange={handleValueChange("email")}
                    />
                </div>

                <div className="relative w-2/12">
                    <label
                        htmlFor="end-date"
                        className="text-[#6B7280] text-xs"
                    >
                        Date de début
                    </label>

                    <Input
                        id="end-date"
                        className="text-[#4B5563]"
                        type="date"
                        placeholder="Rechercher un utilisateur à partir de l’adresse email"
                        value={params.startDate}
                        onChange={handleValueChange("startDate")}
                        min={dateMetadata.min}
                        max={params.endDate}
                    />
                </div>

                <div className="relative w-2/12">
                    <label
                        htmlFor="start-date"
                        className="text-[#6B7280] text-xs"
                    >
                        Date de fin
                    </label>

                    <Input
                        id="start-date"
                        className="text-[#4B5563]"
                        type="date"
                        placeholder="Rechercher un utilisateur à partir de l’adresse email"
                        value={params.endDate}
                        onChange={handleValueChange("endDate")}
                        min={params.startDate}
                        max={dateMetadata.max}
                    />
                </div>

                <Button
                    type="submit"
                    className="w-2/12 mb-px"
                    loading={loading}
                >
                    Rechercher
                </Button>
            </div>
        </form>
    );
};

export default LogsFilters;
