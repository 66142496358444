import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import config from "@/config";
import { PAGES, RESPONSE_STATUS } from "@/constants";
import { auth, userHaveOneRoles, userHavePermissions } from "@/helpers";

interface Props {
    children: React.ReactElement;
    permissions?: string[];
    roles?: string[];
}

export const Auth = ({ children, permissions = [], roles = [] }: Props) => {
    const navigateState = { previousUrl: window.location.pathname };
    const { pathname } = useLocation();

    return (
        <>
            {auth()?.isLogin ? (
                <>
                    {auth()?.user?.confirmOtp ? (
                        <>
                            {userHavePermissions(permissions) &&
                            userHaveOneRoles(roles) ? (
                                children
                            ) : (
                                <Navigate
                                    to={`/${RESPONSE_STATUS.UNAUTHORIZED}`}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            {pathname === PAGES.VALIDATE_OTP ? (
                                children
                            ) : (
                                <Navigate to={PAGES.VALIDATE_OTP} />
                            )}
                        </>
                    )}
                </>
            ) : (
                <Navigate
                    to={config.auth.redirectLogoutUrl}
                    state={navigateState}
                />
            )}
        </>
    );
};

export const Guest = ({ children }: Props) => {
    const navigateState = { previousUrl: window.location.pathname };

    return (
        <>
            {!auth()?.isLogin ? (
                children
            ) : (
                <Navigate
                    to={config.auth.redirectLoginUrl}
                    state={navigateState}
                />
            )}
        </>
    );
};
