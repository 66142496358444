import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DATATABLE } from "../constants";
import { FilterKycParamsType, KycListDataType, KycType } from "../types";

interface CounterState {
    currentKyc: null | KycType;
    kycListData: KycListDataType;
    filterKycParams: FilterKycParamsType;
}

const initialState: CounterState = {
    currentKyc: null,
    kycListData: {
        currentPageData: [],
        total: 0
    },
    filterKycParams: {
        pageIndex: DATATABLE.PAGE_INDEX,
        pageSize: DATATABLE.PAGE_SIZE,
        searchValue: "",
        createdDate: {
            start: "",
            end: ""
        },
        validationDate: "",
        statusKyc: null,
        statusKycTransfer: null,
        statusKycCard: null,
        statusAccount: null,
        country: null,
        validatedBy: []
    }
};

const kycSlice = createSlice({
    name: "kyc",
    initialState,
    reducers: {
        selectKyc: (state, action: PayloadAction<KycType | null>) => {
            state.currentKyc = action.payload;
        },
        updateKycFilterValue: (
            state,
            action: PayloadAction<FilterKycParamsType>
        ) => {
            state.filterKycParams = action.payload;
        },
        updateKycListData: (state, action: PayloadAction<KycListDataType>) => {
            state.kycListData = action.payload;
        }
    }
});

export const { selectKyc, updateKycFilterValue, updateKycListData } =
    kycSlice.actions;

const kycReducer = kycSlice.reducer;

export default kycReducer;
